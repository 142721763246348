import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  ControlsOf,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SiteSavings } from './site-savings-form.model';

@UntilDestroy()
@Component({
  selector: 'mpv-site-savings-form',
  templateUrl: './site-savings-form.component.html',
  styleUrls: ['./site-savings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteSavingsFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SiteSavingsFormComponent),
      multi: true,
    },
  ],
})
export class SiteSavingsFormComponent
  extends ControlValueAccessor<SiteSavings>
  implements OnInit, Validator
{
  savingsForm: FormGroup<ControlsOf<SiteSavings>>;

  get autoConsumedEnergy() {
    return this.savingsForm.get('autoConsumedEnergy');
  }

  get autoConsumptionRatio() {
    return this.savingsForm.get('autoConsumptionRatio');
  }

  get gridTariff() {
    return this.savingsForm.get('gridTariff');
  }

  get savings() {
    return this.savingsForm.get('savings');
  }

  get consumptionProfile() {
    return this.savingsForm.get('consumptionProfiles');
  }

  constructor() {
    super();

    this.savingsForm = new FormGroup<ControlsOf<SiteSavings>>({
      autoConsumedEnergy: new FormControl(null, Validators.min(0)),
      autoConsumptionRatio: new FormControl(null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      gridTariff: new FormControl(null, Validators.min(0)),
      savings: new FormControl(null, Validators.min(0)),
      consumptionProfiles: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.savingsForm.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChange!(value));
  }

  writeValue(value: SiteSavings): void {
    this.savingsForm.patchValue(value);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.savingsForm.invalid ? { invalid: true } : null;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.savingsForm.disable() : this.savingsForm.enable();
  }
}
