import { BehaviorSubject, Observable } from 'rxjs';
import {
  first,
  map,
  mergeMap,
  pluck,
  shareReplay,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { STATUS } from '@multisite-pv/app.reference';
import { MessageDialogComponent } from '@multisite-pv/shared/components/message-dialog';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';

import { MapCoordinate, MapPolygon } from './site-map';
import { SiteMapDialogComponent } from './site-map-dialog/site-map-dialog.component';
import { Site, SiteForm } from './site.model';
import { SiteRepository } from './store';

export const DEFAULT_SITE_MAP_DIALOG_OPTIONS: MatDialogConfig = {
  height: '660px',
  width: '2048px',
  closeOnNavigation: true,
  disableClose: true,
};

@Component({
  selector: 'mpv-site-page',
  templateUrl: './site-page.component.html',
  styleUrls: ['./site-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitePageComponent implements OnInit {
  previousSite$ = new BehaviorSubject<any>(null);
  nextSite$ = new BehaviorSubject<any>(null);

  selectedSite$ = new BehaviorSubject<any>({});
  siteForm: FormGroup<ControlsOf<SiteForm>>;

  polygons$ = this.selectedSite$.pipe(
    pluck('polygons'),
    tap((polygons) => (this.polygons = polygons)),
  );

  polygonsLoading$ = this.selectedSite$.pipe(
    pluck('polygonsLoading'),
    shareReplay(1),
  );

  center$: Observable<MapCoordinate> = this.selectedSite$.pipe(
    map<Site, MapCoordinate>(({ latitude, longitude }) => ({
      latitude,
      longitude,
    })),
  );

  bounds$ = this.selectedSite$.pipe(pluck('bounds'), shareReplay(1));

  polygons: Array<MapPolygon> = [];

  get siteName() {
    return this.selectedSite$.value?.name;
  }
  get siteDescription() {
    return this.selectedSite$.value?.description;
  }
  get siteStatus() {
    return this.selectedSite$.value?.status;
  }

  get loading() {
    return this.selectedSite$.value?.loading;
  }

  get selectedSite() {
    return this.selectedSite$.value;
  }

  get center() {
    return {
      latitude: this.siteForm.value.location?.latitude,
      longitude: this.siteForm.value.location?.longitude,
    } as MapCoordinate;
  }

  constructor(
    private siteRepository: SiteRepository,
    private matDialog: MatDialog,
    private router: Router,
  ) {
    this.siteForm = new FormGroup<ControlsOf<SiteForm>>({
      location: new FormControl(null, [Validators.required]),
      layout: new FormControl(null),
      solarPotential: new FormControl(null),
      surfaceAnalysis: new FormControl(null),
      economics: new FormControl(null),
      savings: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.initializeDefaultValue();
  }

  private initializeDefaultValue() {
    this.siteRepository.selectedEntity$.subscribe((selectedSite) => {
      if (!!selectedSite) {
        this.selectedSite$.next(selectedSite);
        this.setDefaultValue(selectedSite);
      }
    });

    this.siteRepository.filterSites$.subscribe((sites) => {
      const elementIndex = sites.findIndex(
        (site) => site.id === this.selectedSite.id,
      );
      if (elementIndex === 0) {
        // If the site is the first element
        this.nextSite$.next(sites[elementIndex + 1]);
        this.previousSite$.next(null);
      } else if (elementIndex === sites.length - 1) {
        // If the site is the last element
        this.nextSite$.next(null);
        this.previousSite$.next(sites[elementIndex - 1]);
      } else {
        this.nextSite$.next(sites[elementIndex + 1]);
        this.previousSite$.next(sites[elementIndex - 1]);
      }
    });
  }

  private setDefaultValue(selectedSite: Site) {
    this.siteForm.patchValue({
      location: {
        address: selectedSite.address,
        country: selectedSite.country,
        latitude: selectedSite.latitude,
        longitude: selectedSite.longitude,
      },
      layout: {
        chineseHatLayout: selectedSite.chineseHatLayout,
        tilt: selectedSite.tilt,
        orientation: selectedSite.orientation,
        interRowSpacing: selectedSite.interRowSpacing,
      },
      solarPotential: {
        numOfPvModules: selectedSite.numOfPvModules,
        installedCapacity: selectedSite.installedCapacity,
        yearlyProduction: selectedSite.yearlyProduction,
      },
      surfaceAnalysis: {
        totalSurface: selectedSite.totalSurface,
        availableSurface: selectedSite.availableSurface,
        usedSurface: selectedSite.usedSurface,
        usableSurface: selectedSite.usableSurface,
      },
      economics: {
        capex: selectedSite.capex,
        projectDuration: selectedSite.projectDuration,
        wacc: selectedSite.wacc,
        lcoe: selectedSite.lcoe,
        opex: selectedSite.opex,
        discountRate: selectedSite.discountRate,
        degradationFactor: selectedSite.degradationFactor,
      },
      savings: {
        autoConsumedEnergy: selectedSite.autoConsumedEnergy,
        autoConsumptionRatio: selectedSite.autoConsumptionRatio,
        gridTariff: selectedSite.gridTariff,
        savings: selectedSite.savings,
        consumptionProfiles: selectedSite.consumptionProfiles,
      },
    });
    this.siteForm.markAsPristine();
    if (selectedSite.status === STATUS.ONGOING) {
      this.siteForm.disable();
    } else {
      this.siteForm.enable();
    }
  }

  onCancel() {
    this.setDefaultValue(this.selectedSite);
  }

  onSave() {
    if (this.siteForm.valid) {
      const data = {
        title: `Update ${this.siteName}`,
        mode: 'edit',
        name: this.siteName,
      };
      this.matDialog
        .open(MessageDialogComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((isconfirm: boolean) => {
          if (isconfirm) {
            this.siteRepository.updateSite(
              this.selectedSite.projectId,
              this.mapFormToSite(),
            );
          }
        });
    }
  }

  navigateToPreviousSite() {
    this.router.navigate([
      'projects',
      this.previousSite$.value?.projectId,
      'sites',
      this.previousSite$.value?.id,
    ]);
  }

  navigateToNextSite() {
    this.router.navigate([
      'projects',
      this.nextSite$.value?.projectId,
      'sites',
      this.nextSite$.value?.id,
    ]);
  }

  mapFormToSite() {
    const {
      location,
      layout,
      solarPotential,
      surfaceAnalysis,
      economics,
      savings,
    } = this.siteForm.value;

    return {
      ...this.selectedSite,
      ...location,
      ...layout,
      ...solarPotential,
      ...surfaceAnalysis,
      ...economics,
      ...savings,
    };
  }

  onEditMap(): void {
    this.bounds$
      .pipe(
        first(),
        withLatestFrom(this.center$),
        mergeMap(([bounds, center]) =>
          this.matDialog
            .open(SiteMapDialogComponent, {
              ...DEFAULT_SITE_MAP_DIALOG_OPTIONS,
              data: { polygons: this.polygons, center, bounds },
            })
            .afterClosed(),
        ),
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.siteRepository.savePolygons(
          this.selectedSite.projectId,
          this.selectedSite.id,
          data,
        );
      });
  }

  disableButton() {
    return this.siteForm.invalid || this.siteStatus === STATUS.ONGOING;
  }
}
