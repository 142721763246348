import { BehaviorSubject } from 'rxjs';

import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { STATUS } from '@multisite-pv/app.reference';

import { REFERENCE } from './upload-file.constant';

@Component({
  selector: 'mpv-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFileComponent),
      multi: true,
    },
  ],
})
export class UploadFileComponent implements ControlValueAccessor, Validator {
  @ViewChild('importFile', { read: ElementRef }) importFile:
    | ElementRef
    | undefined;
  @Input() set disable(value: boolean) {
    this.disabled$.next(value);
  }
  @Input() set status(status: string) {
    if (status) {
      this.status$.next(status);
    }
  }
  status$ = new BehaviorSubject<string>('default');
  reference = REFERENCE;
  ongoing = STATUS.ONGOING;
  disabled$ = new BehaviorSubject<boolean>(false);
  get disabled() {
    return this.disabled$.value;
  }

  file: File | undefined;
  dragover: boolean = false;
  formats = '.xlsx';
  onChange = (_: File | undefined) => {};
  onTouch = () => {};
  constructor() {}
  validate(): ValidationErrors | null {
    return !this.file ? { invalid: true } : null;
  }

  writeValue(file: File): void {
    this.file = file;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  fileHandler(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (this.isFileValid(target)) {
      this.applyChanges(target?.files![0]);
    }
  }

  onDrag(event: DragEvent) {
    this.callDragEvents(event);
    this.dragover = true;
  }

  onDragLeave(event: DragEvent) {
    this.callDragEvents(event);
    this.dragover = false;
  }

  onDrop(event: DragEvent) {
    this.dragover = false;
    if (!this.disabled && this.isFileValid(event.dataTransfer)) {
      const file = event.dataTransfer!.files![0];
      if (this.formats.includes(file!.type)) {
        this.applyChanges(file);
      }
    }
  }

  onBrowse() {
    if (!!this.importFile) {
      this.importFile.nativeElement.click();
    }
  }

  private isFileValid(target: HTMLInputElement | DataTransfer | null) {
    return (
      !!target &&
      !!target.files &&
      target.files.length > 0 &&
      this.formats.includes(target.files[0].name.split('.')[1])
    );
  }

  private applyChanges(file: File) {
    this.file = file;
    this.onChange(this.file);
    this.onTouch();
    if (!!this.importFile) {
      this.importFile.nativeElement.value = '';
    }
  }

  private callDragEvents(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
