import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  API_BASE_URL,
  environment,
  IS_ENV_PRODUCTION,
  MAP_CONFIG,
  WEB_SOCKET_URL,
} from '@multisite-pv/environment';
import { ReactiveComponentModule } from '@ngrx/component';
import {
  AuthInterceptor,
  AuthModule,
  AuthService,
  FileUploadInterceptor,
} from '@oculus/auth';
import { DrawerModule } from '@oculus/components/drawer';
import { FooterModule } from '@oculus/components/footer';
import { HeaderModule } from '@oculus/components/header';

import { PageNotFoundModule } from './+page-not-found';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SENTRY_DSN, SentryService } from './core/sentry';
import { TranslocoRootModule } from './core/transloco';
import { MpvHeaderModule } from './shared/components/mpv-header';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveComponentModule,
    FlexLayoutModule,
    AuthModule.forRoot(environment.auth),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslocoRootModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // components
    PageNotFoundModule,
    DrawerModule,
    HeaderModule,
    FooterModule,
    MpvHeaderModule,
    MatIconModule,
  ],
  providers: [
    { provide: IS_ENV_PRODUCTION, useValue: environment.production },
    { provide: API_BASE_URL, useValue: environment.api?.baseUrl },
    { provide: SENTRY_DSN, useValue: environment.sentry?.dsn },
    { provide: WEB_SOCKET_URL, useValue: environment.webSocket?.url },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FileUploadInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: !!environment.sentry?.dsn ? SentryService : ErrorHandler,
    },
    {
      provide: MAP_CONFIG,
      useValue: environment.map,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      } as MatFormFieldDefaultOptions,
    },
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
