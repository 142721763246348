<ng-container *transloco="let t; read: 'page-not-found'">
  <mpv-generic-error
    icon="search_off"
    actionIcon="home"
    [title]="t('labels.title')"
    [message]="t('messages.pageNotFound')"
    [actionLabel]="t('labels.action')"
    (actionClicked)="onClickAction()"
  ></mpv-generic-error>
</ng-container>
