import { Directive } from '@angular/core';
import { select, Store, StoreDef } from '@ngneat/elf';
import {
  addEntities,
  EntitiesRef,
  EntitiesState,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntitiesById,
} from '@ngneat/elf-entities';

import { Entity } from '../model/entity.model';
import { State } from '../model/state.model';

@Directive({})
export abstract class EntityStateRepository<
  T extends State &
    EntitiesState<EntitiesRef<'entities', 'ids', 'idKey'>> & {
      activeId: U['id'];
    },
  U extends Entity,
> {
  constructor(protected store: Store<StoreDef<T>>) {}
  state$ = this.store;
  entities$ = this.store.pipe(selectAllEntities());
  selectedEntity$ = this.store.pipe(selectActiveEntity());
  loading$ = this.store.pipe(select((state) => state.loading));
  loaded$ = this.store.pipe(select((state) => state.loaded));
  error$ = this.store.pipe(select((state) => state.error));

  setState(newState: Partial<T>) {
    this.store.update((state) => ({ ...state, ...newState }));
  }

  clearState() {
    this.store.update(() => this.store.initialState);
  }

  setEntities(entities: Array<U>) {
    this.store.update(setEntities(entities));
  }

  addEntity(entity: U) {
    this.store.update(addEntities(entity));
  }

  upsertEntity(id: U['id'], entity: Partial<U>) {
    this.store.update(
      upsertEntitiesById(id, {
        updater: (prevValue) => ({ ...prevValue, ...entity }),
        creator: () => ({ id, ...entity }),
      }),
    );
  }
  updateEntity(id: U['id'], entity: Partial<U>) {
    this.store.update(
      updateEntities(id, (prevValue) => ({ ...prevValue, ...entity })),
    );
  }

  selectEntity(id: U['id'] | null) {
    this.store.update(setActiveId(id));
  }

  resetEntities() {
    this.store.reset();
  }
}
