<div *ngIf="apiLoaded$ | ngrxPush" class="mpv-site-map-container">
  <google-map
    #map
    [options]="mapOptions"
    [width]="width"
    [height]="height"
    data-testid="map"
  >
  </google-map>

  <mpv-site-map-toolbox
    *ngIf="editable$ | ngrxPush"
    class="mpv-site-map-toolbox mat-elevation-z1"
    [tools]="tools"
    [selected]="tools[0]"
    (toolSelected)="onClickTool($event)"
  ></mpv-site-map-toolbox>
</div>
