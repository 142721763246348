import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  ControlsOf,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PVModule } from './project-pv-details-form.model';

@UntilDestroy()
@Component({
  selector: 'mpv-project-pv-details-form',
  templateUrl: './project-pv-details-form.component.html',
  styleUrls: ['./project-pv-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectPvDetailsFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProjectPvDetailsFormComponent),
      multi: true,
    },
  ],
})
export class ProjectPvDetailsFormComponent
  extends ControlValueAccessor<PVModule>
  implements OnInit, Validator
{
  @Input() readOnly: boolean = false;
  pvDetailsForm: FormGroup<ControlsOf<PVModule>>;

  get pvCapacity() {
    return this.pvDetailsForm.get('pvCapacity');
  }

  get pvWidth() {
    return this.pvDetailsForm.get('pvWidth');
  }

  get pvHeight() {
    return this.pvDetailsForm.get('pvHeight');
  }

  constructor() {
    super();

    this.pvDetailsForm = new FormGroup<ControlsOf<PVModule>>({
      pvCapacity: new FormControl(null, [
        Validators.required,
        Validators.min(1),
      ]),
      pvWidth: new FormControl(null, [Validators.required, Validators.min(1)]),
      pvHeight: new FormControl(null, [Validators.required, Validators.min(1)]),
    });
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.pvDetailsForm.invalid ? { invalid: true } : null;
  }

  ngOnInit(): void {
    this.pvDetailsForm.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChange!(value));
  }

  writeValue(value: PVModule): void {
    this.pvDetailsForm.patchValue(value);
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.pvDetailsForm.disable() : this.pvDetailsForm.enable();
  }
}
