import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import {
  AbstractControl,
  ControlsOf,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { ValidationErrors } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SiteLayout } from './site-layout-form.model';

@UntilDestroy()
@Component({
  selector: 'mpv-site-layout-form',
  templateUrl: './site-layout-form.component.html',
  styleUrls: ['./site-layout-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteLayoutFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SiteLayoutFormComponent),
      multi: true,
    },
  ],
})
export class SiteLayoutFormComponent
  extends ControlValueAccessor<SiteLayout>
  implements OnInit, Validator
{
  layoutForm: FormGroup<ControlsOf<SiteLayout>>;

  get tilt() {
    return this.layoutForm.get('tilt');
  }

  get orientation() {
    return this.layoutForm.get('orientation');
  }

  get interRowSpacing() {
    return this.layoutForm.get('interRowSpacing');
  }

  constructor() {
    super();

    this.layoutForm = new FormGroup<ControlsOf<SiteLayout>>({
      chineseHatLayout: new FormControl(false),
      tilt: new FormControl(null, [Validators.min(0), Validators.max(90)]),
      orientation: new FormControl(null, [
        Validators.min(0),
        Validators.max(360),
      ]),
      interRowSpacing: new FormControl(null, Validators.min(0)),
    });
  }

  ngOnInit(): void {
    this.layoutForm.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChange!(value));
  }

  writeValue(value: SiteLayout): void {
    this.layoutForm.patchValue(value);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.layoutForm.invalid ? { invalid: true } : null;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.layoutForm.disable() : this.layoutForm.enable();
  }
}
