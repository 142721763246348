import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ContentHeaderModule } from '../content-header';
import { PageContentComponent } from './page-content.component';

@NgModule({
  declarations: [PageContentComponent],
  imports: [CommonModule, ContentHeaderModule, FlexLayoutModule],
  exports: [PageContentComponent],
})
export class PageContentModule {}
