import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SiteMapTool } from './site-map-toolbox.model';

@Component({
  selector: 'mpv-site-map-toolbox',
  templateUrl: './site-map-toolbox.component.html',
  styleUrls: ['./site-map-toolbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMapToolboxComponent<T = any> {
  @Input() tools: Array<SiteMapTool<T>> = [];
  @Input() selected?: SiteMapTool;

  @Output() toolSelected = new EventEmitter<SiteMapTool<T>>();

  onSelectTool(tool: SiteMapTool) {
    this.toolSelected.emit(tool);
  }
}
