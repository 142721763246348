import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HelpyModule } from '../helpy';
import { FieldSuffixComponent } from './field-suffix.component';

@NgModule({
  declarations: [FieldSuffixComponent],
  imports: [CommonModule, HelpyModule, FlexLayoutModule],
  exports: [FieldSuffixComponent],
})
export class FieldSuffixModule {}
