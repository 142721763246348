<div
  fxLayout="row"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
  *ngIf="!!project"
  data-testid="header-container"
>
  <mat-icon data-testid="folder-icon" class="mpv-project-icon">source</mat-icon>
  <div fxLayout="column" fxLayoutGap="2px">
    <span data-testid="project-name" class="mpv-project-name">
      <a (click)="onClick()">{{ project?.name | titlecase }}</a></span
    >
    <span data-testid="project-company" class="mpv-project-company">
      {{ project?.company | titlecase }}</span
    >
  </div>
</div>
