<div
  *ngIf="loading; else showContent"
  class="mpv-skeleton-loader"
  data-testid="loader"
>
  <ngx-skeleton-loader [theme]="loaderConfig.title"></ngx-skeleton-loader>

  <ngx-skeleton-loader
    [theme]="loaderConfig.description"
    count="2"
  ></ngx-skeleton-loader>
</div>

<ng-template #showContent>
  <h1 *ngIf="title" class="mpv-content-title" data-testid="title">
    {{ title }}
  </h1>

  <p
    *ngIf="description"
    class="mpv-content-description"
    data-testid="description"
  >
    {{ description }}
  </p>
  <div fxLayout="row">
    <mpv-site-status
      *ngIf="status"
      [status]="status"
      data-testid="status"
    ></mpv-site-status>
  </div>
</ng-template>
