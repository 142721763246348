import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectDetailsResolver } from '@multisite-pv/+project/project-details';

import { SitePageComponent } from './site-page.component';
import { SitePageResolver } from './site-page.resolver';
import { SiteProposalResolver } from './site-proposal.resolver';
import { SiteResolver } from './site.resolver';

const routes: Routes = [
  {
    path: '',
    component: SitePageComponent,
    resolve: {
      sites: SiteResolver,
      site: SitePageResolver,
      project: ProjectDetailsResolver,
      proposal: SiteProposalResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteRoutingModule {}
