import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@oculus/auth';
import { DrawerNavLink } from '@oculus/components/drawer';

import { ProjectRepository } from './+project/store';
import { SiteRepository } from './+site';
import { NotificationService } from './core';

@Component({
  selector: 'mpv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'multisitepv-app';
  project_route = '/projects';
  hideHeader$: Observable<boolean>;
  navLinks: Array<DrawerNavLink> = [
    { icon: 'source', label: 'Projects', path: 'projects' },
  ];
  readonly version = '1.1.0';
  selectedProject$ = this.projectRepo.selectedEntity$;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private siteRepository: SiteRepository,
    private projectRepo: ProjectRepository,
    private router: Router,
  ) {
    this.hideHeader$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((ss: NavigationEnd) => this.project_route === ss?.url),
    );
  }

  ngOnInit(): void {
    this.processNotification();
  }

  logout() {
    this.authService.logout();
  }

  processNotification() {
    return this.notificationService.messages$.subscribe((response) => {
      const data = response.data;
      let errorMessages = null;
      if (data?.status?.errorMessages) {
        errorMessages = JSON.parse(data?.errorMessages);
      }

      if (!!data?.generatedBy) {
        switch (data?.generatedBy) {
          case 'siteImport':
            this.siteRepository.processUpdate(data?.projectId, {
              status: data?.status,
              errorMessages,
            });
            break;
          case 'siteComputation':
            this.siteRepository.getComputationUpdate(
              data?.projectId,
              data?.siteId,
              {
                status: data?.status,
              },
            );
            break;
          case 'projectExport':
            this.siteRepository.downloadExport(
              data?.projectId,
              data?.signedUrl,
            );
            break;
        }
      }
    });
  }
}
