import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mpv-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeaderComponent {
  @Input() title?: string;
  @Input() icon?: string;
}
