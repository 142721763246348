import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ProjectRepository } from '../store';

@Injectable({
  providedIn: 'root',
})
export class ProjectDetailsResolver implements Resolve<boolean> {
  constructor(private projectRepository: ProjectRepository) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { projectId } = route.params;
    this.projectRepository.getProject(projectId);
    this.projectRepository.selectEntity(projectId);
    return of(true);
  }
}
