import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SiteImportLogsModule } from '@multisite-pv/+site/site-import-logs';
import { SiteListModule } from '@multisite-pv/+site/site-list';
import { ContentHeaderModule } from '@multisite-pv/shared/components/content-header';
import { PageContentModule } from '@multisite-pv/shared/components/page-content';
import { SectionHeaderModule } from '@multisite-pv/shared/components/section-header';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';

import { ProjectPvDetailsFormModule } from '../project-pv-details-form';
import { ProjectDetailsComponent } from './project-details.component';

@NgModule({
  declarations: [ProjectDetailsComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    MatButtonModule,
    TranslocoModule,
    SiteImportLogsModule,
    SiteListModule,
    SectionHeaderModule,
    ContentHeaderModule,
    ProjectPvDetailsFormModule,
    PageContentModule,
  ],
})
export class ProjectDetailsModule {}
