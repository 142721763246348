import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mpv-field-suffix',
  templateUrl: './field-suffix.component.html',
  styleUrls: ['./field-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldSuffixComponent {
  @Input() unit: string = '-';
  @Input() helpyKey: string = '';
}
