<div fxLayout="column" fxLayoutGap="20px" *transloco="let t; read: 'project'">
  <div fxFill>
    <div
      fxFill
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="hasData$ | ngrxPush"
    >
      <button
        mat-button
        color="primary"
        (click)="onExport()"
        data-testid="export_button"
        [disabled]="project.exporting"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
          <mat-icon *ngIf="!project.exporting; else exporting">
            cloud_download
          </mat-icon>
          <ng-template #exporting>
            <mat-spinner diameter="20"></mat-spinner>
          </ng-template>

          <span>
            {{ t('labels.exportFile') }}
          </span>
        </div>
      </button>
      <button
        *ngIf="!isOnImport"
        mat-button
        color="primary"
        (click)="onImport()"
        data-testid="update_site_button"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
          <mat-icon> cloud_upload </mat-icon>
          <span>
            {{ t('labels.updateSite') }}
          </span>
        </div>
      </button>

      <button
        *ngIf="isOnImport"
        mat-button
        color="primary"
        (click)="onImport()"
        data-testid="cancel_site_button"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
          <mat-icon> cancel </mat-icon>
          <span>
            {{ t('labels.close') }}
          </span>
        </div>
      </button>
    </div>
  </div>
  <div
    class="full-width"
    *ngIf="(!(hasData$ | ngrxPush) || isOnImport) && !(loading$ | ngrxPush)"
  >
    <mpv-sites-upload-form
      class="full-width"
      [selectedProject]="selectedProject$ | ngrxPush"
    ></mpv-sites-upload-form>
  </div>

  <mat-table
    class="mpv-full-width"
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
  >
    <ng-container matColumnDef="update">
      <mat-cell *matCellDef="let element">
        <ng-template #importForm>
          <mpv-sites-upload-form
            class="full-width"
            [selectedProject]="selectedProject$ | ngrxPush"
          ></mpv-sites-upload-form>
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="identifier">
      <mat-header-cell *matHeaderCellDef data-testid="site_name">{{
        t('labels.siteName')
      }}</mat-header-cell>

      <mat-cell *matCellDef="let element">
        <div
          *ngIf="!element.loading; else loading"
          fxLayout="row"
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mpv-site-status
            [status]="element.status"
            [short]="true"
          ></mpv-site-status>
          <span class="mpv-clickable" (click)="onSelect(element)">
            {{ element.name }}
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        {{ t('labels.status') }}
      </mat-header-cell>
      <mat-cell *matCellDef="let site">
        <mpv-site-status
          [status]="site.status"
          [short]="true"
        ></mpv-site-status>
      </mat-cell>
    </ng-container>

    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of columnsWithoutAction"
    >
      <mat-header-cell *matHeaderCellDef>{{
        t('labels.' + column)
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="!element.loading; else loading">
          {{ element[column] }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <ng-container *ngIf="loading$ | ngrxPush">
    <ngx-skeleton-loader [count]="5"></ngx-skeleton-loader>
  </ng-container>

  <mat-paginator
    [length]="dataSource.data.length"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 20]"
  >
  </mat-paginator>
  <ng-template #loading>
    <ngx-skeleton-loader [theme]="loaderCss.column"></ngx-skeleton-loader>
  </ng-template>
</div>
