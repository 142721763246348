import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

import { SiteMapToolboxComponent } from './site-map-toolbox.component';

@NgModule({
  declarations: [SiteMapToolboxComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatButtonToggleModule,
  ],
  exports: [SiteMapToolboxComponent],
})
export class SiteMapToolboxModule {}
