<form
  *transloco="let t; read: 'site.layout'"
  [formGroup]="layoutForm"
  data-testid="layout"
  fxLayout="column"
  fxLayoutGap="0.5rem"
>
  <div fxLayout="row wrap" fxLayoutGap="1rem">
    <mat-form-field fxFlex>
      <mat-label>{{ t('tilt.label') }}</mat-label>
      <input matInput formControlName="tilt" data-testid="tilt" type="number" />
      <mpv-field-suffix
        matSuffix
        data-test="tiltSuffix"
        helpyKey="tilt"
        [unit]="t('tilt.unit')"
      ></mpv-field-suffix>
      <mat-error *ngIf="tilt?.hasError" data-testid="tilt-invalid-value">
        {{ t('tilt.messages.invalidValue') }}</mat-error
      >
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ t('orientation.label') }}</mat-label>
      <input
        matInput
        formControlName="orientation"
        data-testid="orientation"
        type="number"
      />
      <mpv-field-suffix
        matSuffix
        data-test="orientationSuffix"
        helpyKey="orientation"
        [unit]="t('orientation.unit')"
      ></mpv-field-suffix>
      <mat-error
        *ngIf="orientation?.hasError"
        data-testid="orientation-invalid-value"
      >
        {{ t('orientation.messages.invalidValue') }}</mat-error
      >
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ t('interRowSpacing.label') }}</mat-label>
      <input
        matInput
        formControlName="interRowSpacing"
        data-testid="inter-row-spacing"
        type="number"
      />
      <mpv-field-suffix
        matSuffix
        data-test="interRowSpacingSuffix"
        helpyKey="interRowSpacing"
        [unit]="t('interRowSpacing.unit')"
      ></mpv-field-suffix>
      <mat-error
        *ngIf="interRowSpacing?.hasError"
        data-testid="interRowSpacing-invalid-value"
      >
        {{ t('interRowSpacing.messages.invalidValue') }}</mat-error
      >
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="1rem" class="mpv-site-layout-chinese-hat">
    <div fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="start start">
      <mat-label>{{ t('chineseHatLayout.label') }}</mat-label>
      <mpv-helpy key="chineseHat"></mpv-helpy>
    </div>
    <mat-radio-group
      formControlName="chineseHatLayout"
      data-testid="chinese-hat-layout"
      fxLayout="row"
      fxLayoutGap="0.75rem"
    >
      <mat-radio-button data-testid="chinese-hat-layout-true" [value]="true">
        {{ t('chineseHatLayout.values.true') }}
      </mat-radio-button>

      <mat-radio-button data-testid="chinese-hat-layout-false" [value]="false">
        {{ t('chineseHatLayout.values.false') }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</form>
