import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';

import { LOADER_CSS } from '../project.constant';
import { Project, ProjectForm } from '../project.model';
import { ProjectRepository } from '../store';

@Component({
  selector: 'mpv-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailsComponent implements OnInit {
  loaderCss = LOADER_CSS;
  projectDetailsForm: FormGroup<ControlsOf<ProjectForm>>;
  selectedProject$ = new BehaviorSubject<any>({});

  constructor(private projectRepository: ProjectRepository) {
    this.projectDetailsForm = new FormGroup<ControlsOf<ProjectForm>>({
      name: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      description: new FormControl(null),
      pvDetails: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.initializeDefaultValue();
  }

  private initializeDefaultValue() {
    this.projectRepository.selectedEntity$.subscribe((selectedProject) => {
      if (!!selectedProject) {
        this.selectedProject$.next(selectedProject);
        this.setDefaultValue(selectedProject);
      }
    });
  }

  private setDefaultValue(selectedProject: Project) {
    this.projectDetailsForm.patchValue({
      ...selectedProject,
      pvDetails: {
        pvCapacity: selectedProject.pvCapacity,
        pvHeight: selectedProject.pvHeight,
        pvWidth: selectedProject.pvWidth,
      },
    });
    this.projectDetailsForm.markAsPristine();
  }
}
