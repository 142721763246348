import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';

import { SiteStatusModule } from '@multisite-pv/shared/components/site-status';
import { SiteImportDialogModule } from '../site-import-dialog/site-import-dialog.module';
import { SitesUploadFormModule } from '../sites-upload-form';
import { SiteListComponent } from './site-list.component';

@NgModule({
  declarations: [SiteListComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveComponentModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    TranslocoModule,
    NgxSkeletonLoaderModule,
    SiteImportDialogModule,
    SitesUploadFormModule,
    SiteStatusModule,
  ],
  exports: [SiteListComponent],
})
export class SiteListModule {}
