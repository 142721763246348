<oculus-drawer
  [links]="navLinks"
  headerLogo="assets/logos/logo-mpv.svg"
  headerLogoMini="assets/logos/logo-mpv-small.svg"
  footerLogo="assets/logos/logo-engie-impact-blue.jpg"
  footerLogoMini="assets/logos/logo-engie-small-blue.svg"
>
  <div class="mpv-content" fxLayoutGap="25px">
    <mpv-header
      data-testid="header"
      *ngIf="!(hideHeader$ | ngrxPush)"
      [project]="selectedProject$ | ngrxPush"
    ></mpv-header>
    <router-outlet></router-outlet>
  </div>

  <oculus-footer>
    <div class="mpv-version" data-test="version">version {{ version }}</div>
    <a class="mpv-logout" (click)="logout()">Logout</a>
  </oculus-footer>
</oculus-drawer>
