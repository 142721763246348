export enum SiteStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  ONGOING = 'ongoing',
  WAITING = 'waiting',
}

export const STATUS = {
  SUCCESS: SiteStatus.SUCCESS,
  FAILED: SiteStatus.FAILED,
  ONGOING: SiteStatus.ONGOING,
  WAITING: SiteStatus.WAITING,
};

export const STATUS_COLOR: Record<string, string> = {
  success: '#69af23',
  ongoing: '#f4b323',
  waiting: '#f4b323',
  failed: '#e10019',
};
