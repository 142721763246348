<div
  *ngIf="!!icon || !!title"
  class="mpv-section-header-contents"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="0.4rem"
  data-testid="contents"
>
  <mat-icon
    *ngIf="!!icon"
    class="mpv-section-icon material-symbols-outlined"
    data-testid="icon"
    >{{ icon }}</mat-icon
  >

  <h3 *ngIf="!!title" class="mpv-section-title" data-testid="title">
    {{ title | uppercase }}
  </h3>
</div>
