import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';

import { SiteMapModule } from '../site-map/site-map.module';
import { SiteMapDialogComponent } from './site-map-dialog.component';

@NgModule({
  declarations: [SiteMapDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SiteMapModule,
    TranslocoModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveComponentModule,
  ],
  exports: [SiteMapDialogComponent],
})
export class SiteMapDialogModule {}
