import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@multisite-pv/environment.token';

import { Project } from './project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient,
  ) {}
  createProject(project: Project): Observable<any> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/projects`, this.mapProject(project))
      .pipe(map((project) => this.mapProjectToUI(project)));
  }

  getProjects(): Observable<Array<Project>> {
    return this.http
      .get<Array<any>>(`${this.apiBaseUrl}/projects`)
      .pipe(
        map((projects: any) =>
          projects.map((project: any) => this.mapProjectToUI(project)),
        ),
      );
  }

  getProject(projectId: string): Observable<Project> {
    return this.http
      .get<Array<any>>(`${this.apiBaseUrl}/projects/${projectId}`)
      .pipe(map((projects: any) => this.mapProjectToUI(projects)));
  }

  updateProject(projectId: string, project: Project): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiBaseUrl}/projects/${projectId}`,
        this.mapProject(project),
      )
      .pipe(map((project) => this.mapProjectToUI(project)));
  }

  mapProjectToUI(project: any) {
    return {
      id: project.id,
      name: project.projectName,
      company: project.projectCompany,
      pvHeight: project.pvModuleHeight,
      pvWidth: project.pvModuleWidth,
      pvCapacity: project.pvModuleCapacity,
      description: project.projectDescription || '-',
    };
  }

  mapProject(project: Project): any {
    return {
      id: project.id,
      projectDescription: project.description,
      projectCompany: project.company,
      projectName: project.name,
      pvModuleHeight: project.pvHeight,
      pvModuleWidth: project.pvWidth,
      pvModuleCapacity: project.pvCapacity,
    };
  }
}
