import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { SENTRY_DSN } from './sentry.token';

@Injectable()
export class SentryService implements ErrorHandler {
  constructor(@Inject(SENTRY_DSN) public dsn: string | undefined) {
    this.initSentry(dsn);
  }

  initSentry(dsn?: string): void {
    if (!dsn) {
      return;
    }

    Sentry.init({
      dsn,
    });
  }

  handleError<T = any>(error: T): void {
    if (!this.dsn || !error) {
      return;
    }

    Sentry.captureException(error);
  }
}
