<div
  class="mpv-upload-file-container"
  fxLayout="column"
  *transloco="let t; read: 'project'"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
    (drop)="onDrop($event)"
    (dragover)="onDrag($event)"
    (dragleave)="onDragLeave($event)"
    class="mpv-form-container"
    *ngrxLet="status$ as status"
  >
    <input
      type="file"
      #importFile
      id="importFile"
      [accept]="formats"
      (change)="fileHandler($event)"
      [disabled]="status === ongoing"
    />
    <mat-icon [color]="reference[status]?.color" class="mpv-large-icon">{{
      reference[status]?.icon
    }}</mat-icon>
    <span class="mpv-label">{{
      t('messages.' + reference[status]?.label)
    }}</span>
    <div fxLayout="row">
      <span *ngIf="reference[status]?.descriptions">
        {{ t('messages.' + reference[status]?.descriptions) }}
      </span>
      <div *ngIf="status !== ongoing">
        &nbsp;
        <span class="mpv-clickable">
          {{ t('messages.browseFile') }}
        </span>
        <span> {{ t('messages.browseFileCont') }} </span>
      </div>
    </div>

    <mat-hint *ngIf="status !== ongoing">{{
      t('messages.hints.fileType')
    }}</mat-hint>
  </div>
</div>
