import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

import { MessageDialogComponent } from './message-dialog.component';

@NgModule({
  declarations: [MessageDialogComponent],
  imports: [CommonModule, MatButtonModule, FlexLayoutModule, TranslocoModule],
  exports: [MessageDialogComponent],
})
export class MessageDialogModule {}
