export const LOADER_CSS = {
  name: {
    width: '200px',
    height: '24px',
  },
  company: {
    width: '300px',
    height: '16px',
  },
  input: {
    width: '200px',
    height: '25px',
  },
  column: {
    width: '150px',
  },
};
