<div
  fxLayout="column"
  fxLayoutAlign="start end"
  class="mpv-site-form-container"
>
  <mpv-upload-file
    class="mpv-full-width"
    [formControl]="sitesForm"
    [status]="project?.status"
  ></mpv-upload-file>
  <mpv-site-dl-template class="site-dl-template-offset"></mpv-site-dl-template>
</div>
