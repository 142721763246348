<form
  *transloco="let t; read: 'site.surfaceAnalysis'"
  [formGroup]="surfaceAnalysisForm"
  data-testid="surfaceAnalysis"
  fxLayout="row wrap"
  fxLayoutGap="1rem"
>
  <mat-form-field fxFlex>
    <mat-label>{{ t('totalSurface.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="totalSurface"
      data-testid="totalSurface"
    />
    <mpv-field-suffix
      data-test="totalSurfaceSuffix"
      matSuffix
      helpyKey="totalSurface"
      [unit]="t('usableSurface.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="totalSurface?.errors?.min"
      data-testid="totalSurface-positive"
      >{{ t('totalSurface.messages.positive') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('availableSurface.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="availableSurface"
      data-testid="availableSurface"
    />
    <mpv-field-suffix
      data-test="availableSurfaceSuffix"
      matSuffix
      helpyKey="availableSurface"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="availableSurface?.hasError"
      data-testid="availableSurface-oneOrZero"
      >{{ t('availableSurface.messages.oneOrZero') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('usedSurface.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="usedSurface"
      data-testid="usedSurface"
    />
    <mpv-field-suffix
      data-test="usedSurfaceSuffix"
      matSuffix
      helpyKey="usedSurface"
      [unit]="t('usableSurface.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="usedSurface?.errors?.min"
      data-testid="usedSurface-positive"
      >{{ t('usedSurface.messages.positive') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('usableSurface.label') }}</mat-label>
    <input
      type="number"
      matInput
      formControlName="usableSurface"
      data-testid="usableSurface"
    />

    <mpv-field-suffix
      data-test="usableSurfaceSuffix"
      matSuffix
      helpyKey="usableSurface"
      [unit]="t('usableSurface.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="usedSurface?.hasError"
      data-testid="usedSurface-positive"
      >{{ t('usableSurface.messages.positive') }}</mat-error
    >
  </mat-form-field>
</form>
