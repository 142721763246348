import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mpv-site-dl-template',
  templateUrl: './site-dl-template.component.html',
  styleUrls: ['./site-dl-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteDlTemplateComponent {
  onDownload(): void {
    this.implicitDownload();
  }

  private implicitDownload(): void {
    window.open(this.pathToTemplate, '_blank');
  }

  private get pathToTemplate(): string {
    return `/assets/templates/mpv_latest.xlsx`;
  }
}
