import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SiteRepository } from '@multisite-pv/+site/store';

@Injectable({
  providedIn: 'root',
})
export class SiteResolver implements Resolve<boolean> {
  constructor(private siteRepository: SiteRepository) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { projectId } = route.params;
    this.siteRepository.getSites(projectId);
    return of(true);
  }
}
