import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

const selector = 'mpv-generic-error';

@Component({
  selector,
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericErrorComponent {
  @HostBinding('class') class = selector;

  @Input() icon = 'sentiment_very_dissatisfied';
  @Input() title = 'Generic Error';
  @Input() message: string | undefined =
    'Something went wrong... Please try again';

  @Input() actionIcon = 'refresh';
  @Input() actionLabel = 'Retry';

  @Output() actionClicked = new EventEmitter<void>();

  onClickAction(): void {
    this.actionClicked.emit();
  }
}
