<mpv-page-content
  *transloco="let t; read: 'site'"
  data-testid="content"
  [formGroup]="siteForm"
  fxLayout="column"
  fxLayoutGap="1rem"
>
  <div fxLayout="row">
    <div fxFlex="80">
      <mpv-content-header
        header
        data-testid="header"
        [title]="siteName"
        [description]="siteDescription"
        [loading]="loading"
        [status]="siteStatus"
        fxFill
      ></mpv-content-header>
    </div>
    <div
      fxFlex="20"
      fxLayout="row"
      fxLayoutGap="3rem"
      fxLayoutAlign="end center"
    >
      <div>
        <button
          mat-button
          color="primary"
          (click)="navigateToPreviousSite()"
          [disabled]="
            (previousSite$ | async) === null ||
            (previousSite$ | async) === undefined
          "
          data-testid="previous-site-button"
        >
          <mat-icon>chevron_left</mat-icon>
          {{ (previousSite$ | async)?.name }}
        </button>
      </div>
      <div>
        <button
          mat-button
          color="primary"
          (click)="navigateToNextSite()"
          [disabled]="
            (nextSite$ | async) === null || (nextSite$ | async) === undefined
          "
          data-testid="next-site-button"
        >
          <mat-icon>chevron_right</mat-icon>
          {{ (nextSite$ | async)?.name }}
        </button>
      </div>
    </div>
  </div>

  <!-- Location -->
  <div
    class="mpv-site-location"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="3rem"
  >
    <!-- Map -->
    <mpv-site-impression fxFlex>
      <div class="mpv-site-map">
        <div
          *ngIf="loading || (polygonsLoading$ | ngrxPush)"
          class="mpv-site-map-loader"
          data-testid="map-loader"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <p class="mpv-site-map-loader-text">
            {{ t('map.messages.loading') }}
          </p>
        </div>

        <div
          *ngIf="loading || (polygonsLoading$ | ngrxPush)"
          class="mpv-site-map-loader-bg"
          data-testid="map-loader-bg"
        ></div>

        <mpv-site-map
          data-testid="map"
          height="350"
          [polygons]="polygons$ | ngrxPush"
          [center]="center$ | ngrxPush"
          [bounds]="bounds$ | ngrxPush"
        ></mpv-site-map>

        <button
          *ngIf="!(loading || (polygonsLoading$ | ngrxPush))"
          mat-mini-fab
          data-testid="edit-map"
          (click)="onEditMap()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </mpv-site-impression>

    <span>
      <mpv-section-header
        data-testid="location-header"
        [icon]="t('location.icon')"
        [title]="t('location.label')"
      ></mpv-section-header>

      <mpv-site-location-form
        data-testid="location-form"
        formControlName="location"
      ></mpv-site-location-form>
    </span>
  </div>

  <!-- Surface Analysis -->
  <div class="mpv-site-surface-analysis">
    <mpv-section-header
      data-testid="surface-analysis-header"
      [icon]="t('surfaceAnalysis.icon')"
      [title]="t('surfaceAnalysis.label')"
    ></mpv-section-header>

    <mpv-site-surface-analysis-form
      data-testid="surface-analysis-form"
      formControlName="surfaceAnalysis"
    ></mpv-site-surface-analysis-form>
  </div>

  <!-- Layout -->
  <div class="mpv-site-layout">
    <mpv-section-header
      data-testid="layout-header"
      [icon]="t('layout.icon')"
      [title]="t('layout.label')"
    ></mpv-section-header>

    <mpv-site-layout-form
      data-testid="layout-form"
      formControlName="layout"
    ></mpv-site-layout-form>
  </div>

  <!-- Solar Potential -->
  <div class="mpv-site-solar-potential">
    <mpv-section-header
      data-testid="solar-potential-header"
      [icon]="t('solarPotential.icon')"
      [title]="t('solarPotential.label')"
    ></mpv-section-header>

    <mpv-site-solar-potential-form
      data-testid="solar-potential-form"
      formControlName="solarPotential"
    ></mpv-site-solar-potential-form>
  </div>

  <!-- Economics -->
  <div class="mpv-site-economics">
    <mpv-section-header
      data-testid="economics-header"
      [icon]="t('economics.icon')"
      [title]="t('economics.label')"
    ></mpv-section-header>

    <mpv-site-economics-form
      data-testid="economics-form"
      formControlName="economics"
    ></mpv-site-economics-form>
  </div>

  <!-- Savings -->
  <div class="mpv-site-savings">
    <mpv-section-header
      data-testid="savings-header"
      [icon]="t('savings.icon')"
      [title]="t('savings.label')"
    ></mpv-section-header>

    <mpv-site-savings-form
      data-testid="savings-form"
      formControlName="savings"
    ></mpv-site-savings-form>
  </div>
  <!-- Graph -->
  <div class="mpv-site-graph">
    <mpv-section-header
      data-testid="profile-graph-header"
      [icon]="t('graph.icon')"
      [title]="t('graph.label')"
    ></mpv-section-header>
    <mpv-site-profile-graph
      data-testid="profile-graph"
      [selectedSite]="selectedSite"
    ></mpv-site-profile-graph>
  </div>

  <!-- Actions -->
  <div class="mpv-site-actions" fxLayout="row" fxLayoutGap="0.5rem">
    <button
      mat-flat-button
      color="primary"
      data-testid="save-changes-button"
      [disabled]="disableButton()"
      (click)="onSave()"
    >
      {{ t('actions.update.label') }}
    </button>

    <button
      mat-button
      color="primary"
      data-testid="cancel-changes-button"
      [disabled]="siteForm.pristine"
      (click)="onCancel()"
    >
      {{ t('actions.cancel.label') }}
    </button>
  </div>
</mpv-page-content>
