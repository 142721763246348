import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SiteStatus } from '@multisite-pv/app.reference';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'mpv-site-status',
  templateUrl: './site-status.component.html',
  styleUrls: ['./site-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'site' }],
})
export class SiteStatusComponent {
  @Input() status: SiteStatus | string | undefined;
  @Input() short = false;
}
