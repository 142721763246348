<ngx-skeleton-loader
  data-testid="loading-graph"
  *ngIf="loading$ | ngrxPush; else loaded"
  [theme]="loadingConfig"
></ngx-skeleton-loader>
<ng-template #loaded>
  <mpv-plotly-graph
    data-testid="profile-graph"
    [profiles]="data$ | ngrxPush"
  ></mpv-plotly-graph>
</ng-template>
