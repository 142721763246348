import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ControlsOf,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SiteEconomics } from './site-economics-form.model';

@UntilDestroy()
@Component({
  selector: 'mpv-site-economics-form',
  templateUrl: './site-economics-form.component.html',
  styleUrls: ['./site-economics-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteEconomicsFormComponent),
      multi: true,
    },
  ],
})
export class SiteEconomicsFormComponent
  extends ControlValueAccessor<SiteEconomics>
  implements OnInit
{
  economicsForm: FormGroup<ControlsOf<SiteEconomics>>;

  constructor() {
    super();

    this.economicsForm = new FormGroup<ControlsOf<SiteEconomics>>({
      capex: new FormControl(null),
      projectDuration: new FormControl(null),
      wacc: new FormControl(null),
      lcoe: new FormControl(null),
      opex: new FormControl(null),
      discountRate: new FormControl(null),
      degradationFactor: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.economicsForm.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChange!(value));
  }

  writeValue(value: SiteEconomics): void {
    this.economicsForm.patchValue(value);
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.economicsForm.disable() : this.economicsForm.enable();
  }
}
