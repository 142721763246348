<div
  fxLayout="row"
  fxLayoutGap="4px"
  class="template-question"
  *transloco="let t; read: 'site.downloadTemplate'; scope: 'site'"
>
  <span>{{ t('1st') }}</span>
  <span
    class="mpv-clickable"
    data-test="downloadTemplate"
    (click)="onDownload()"
  >
    {{ t('here') }}
  </span>
  <span>
    {{ t('2nd') }}
  </span>
</div>
