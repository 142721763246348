import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';

import { UploadFileComponent } from './upload-file.component';

@NgModule({
  declarations: [UploadFileComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    FlexLayoutModule,
    TranslocoModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  exports: [UploadFileComponent],
})
export class UploadFileModule {}
