import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlotlyGraphModule } from '@multisite-pv/shared/components/plotly-graph';
import { ReactiveComponentModule } from '@ngrx/component';

import { SiteProfileGraphComponent } from './site-profile-graph.component';

@NgModule({
  declarations: [SiteProfileGraphComponent],
  exports: [SiteProfileGraphComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    PlotlyGraphModule,
    NgxSkeletonLoaderModule,
  ],
})
export class SiteProfileGraphModule {}
