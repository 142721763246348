import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@multisite-pv/environment.token';

import { MapCoordinate, MapPolygon } from './site-map';
import { SignedUrl, Site } from './site.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient,
  ) {}

  getSites(projectId: string): Observable<Site[]> {
    return this.http
      .get<Array<any>>(`${this.apiBaseUrl}/projects/${projectId}/sites`)
      .pipe(
        map((sites: any) => sites.map((site: any) => this.mapSitesToUI(site))),
      );
  }

  getSite(projectId: string, siteId: string): Observable<Site | undefined> {
    return this.http
      .get<Array<any>>(
        `${this.apiBaseUrl}/projects/${projectId}/sites/${siteId}`,
      )
      .pipe(map((site: any) => this.mapSitesToUI(site)));
  }

  updateSite(projectId: string, site: Site): Observable<Site | undefined> {
    return this.http
      .post<any>(
        `${this.apiBaseUrl}/projects/${projectId}/sites/${site.id}`,
        this.mapSiteToBE(site),
      )
      .pipe(map((site: any) => this.mapSitesToUI(site)));
  }

  getImportSignedUrl(projectId: string) {
    const uploadEndpoint = `${this.apiBaseUrl}/projects/${projectId}/imports/sites`;
    return this.http
      .post<{ url: string }>(uploadEndpoint, undefined)
      .pipe(map((response) => response));
  }

  uploadFile(signedUrl: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.put(signedUrl, file);
  }

  getPolygons(projectId: string, siteId: string) {
    return this.http
      .get<Array<any>>(
        `${this.apiBaseUrl}/projects/${projectId}/sites/${siteId}/polygons`,
      )
      .pipe(map((polygons) => polygons.map(this.mapPolygonToFE.bind(this))));
  }

  savePolygons(projectId: string, siteId: string, polygons: Array<MapPolygon>) {
    return this.http
      .post<Array<MapPolygon>>(
        `${this.apiBaseUrl}/projects/${projectId}/sites/${siteId}/polygons`,
        polygons.map(this.mapPolygonToBE),
      )
      .pipe(map((polygons) => polygons.map(this.mapPolygonToFE.bind(this))));
  }

  exportSites(projectId: string) {
    return this.http
      .get(`${this.apiBaseUrl}/projects/${projectId}/export`)
      .pipe(map((response: any) => response));
  }

  downloadExported(signed_url: SignedUrl) {
    return this.http.get(signed_url.url).pipe(
      map((response: any) => {
        const blob = new Blob([response], {
          type: 'applications/octet-stream',
        });
        const resUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = signed_url.filename;
        anchor.href = resUrl;
        anchor.click();
        return response;
      }),
    );
  }

  getProfiles(projectId: string, siteId: string, profileType: string) {
    return this.http
      .get<string>(
        `${this.apiBaseUrl}/projects/${projectId}/sites/${siteId}/profiles?profile=${profileType}`,
      )
      .pipe(map((signedUrl: any) => signedUrl['url']));
  }

  downloadProfiles(url: string, profileType: string) {
    return this.http.get(url).pipe(
      map((response: any) => response),
      mergeMap(
        (data: any) =>
          new Observable((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve.next(reader.result);
              resolve.complete;
            };
            reader.readAsText(data);
          }),
      ),
      map((data: any) => JSON.parse(data)[profileType]),
    );
  }

  mapSitesToUI(site: any): Site {
    return {
      id: site?.siteId,
      projectId: site?.id,
      name: site?.siteName,
      description: site?.siteDescription || '-',
      address: site?.siteAddress,
      country: site?.siteCountry,
      latitude: site?.siteLatitude,
      longitude: site?.siteLongitude,
      chineseHatLayout: site?.siteWithOppositeDirection,
      tilt: site?.siteTilt,
      orientation: site?.siteOrientation,
      interRowSpacing: site?.siteDistanceBetweenRows,
      numOfPvModules: site?.siteNumberOfPVModules,
      installedCapacity: site?.siteInstalledCapacity,
      totalSurface: site?.siteSurfaceArea,
      availableSurface: site?.siteSurfaceRatio,
      usedSurface: site?.siteUsedSurface,
      usableSurface: site?.siteUsableSurface,
      capex: site?.siteCapex,
      opex: site?.siteOpex,
      projectDuration: site?.siteProjectDuration,
      discountRate: site?.siteDiscountRate,
      degradationFactor: site?.siteDegradationFactor,
      wacc: site?.siteWacc,
      lcoe: this.multiplied_by_thousand(site.siteLcoe),
      autoConsumedEnergy: site?.siteAutoConsumedEnergy,
      autoConsumptionRatio: site?.siteAutoConsumptionRatio,
      gridTariff: site?.siteGridTariff,
      savings: site?.siteSavings,
      consumptionProfiles: site?.siteConsumptionProfiles,
      status: site?.siteComputationStatus,
      bounds: [
        {
          latitude: site?.siteMinLat,
          longitude: site?.siteMinLong,
        },
        {
          latitude: site?.siteMaxLat,
          longitude: site?.siteMaxLong,
        },
      ],
      productionKey: site?.siteProductionProfileKey,
      consumptionKey: site?.siteConsumptionProfileKey,
      yearlyProduction: this.divide_by_thousand(site?.siteYearlyProduction),
    };
  }

  mapSiteToBE(site: Site): any {
    return {
      name: site.name,
      address: site.address,
      country: site.country,
      latitude: site.latitude,
      longitude: site.longitude,
      add_opposite_direction: site.chineseHatLayout,
      tilt: site.tilt,
      orientation: site.orientation,
      distance_between_rows: site.interRowSpacing,
      num_of_pv_modules: site.numOfPvModules,
      installed_capacity: site.installedCapacity,
      surface_area: site.totalSurface,
      available_surface_ratio: site.availableSurface,
      used_surface: site.usedSurface,
      usable_surface: site.usableSurface,
      capex: site.capex,
      opex: site.opex,
      project_duration: site.projectDuration,
      discount_rate: site.discountRate,
      degradation_factor: site.degradationFactor,
      wacc: site.wacc,
      lcoe: this.divide_by_thousand(site.lcoe),
      auto_consumed_energy: site.autoConsumedEnergy,
      auto_consumption_ratio: site.autoConsumptionRatio,
      grif_tariff: site.gridTariff,
      savings: site.savings,
      yearly_production: this.multiplied_by_thousand(site.yearlyProduction),
      consumption_profiles: site.consumptionProfiles,
    };
  }

  protected divide_by_thousand(value: number | undefined) {
    return value !== undefined ? value / 1000 : value;
  }

  protected multiplied_by_thousand(value: number | undefined) {
    return value !== undefined ? value * 1000 : value;
  }

  protected mapPolygonToFE(polygon: any): MapPolygon {
    return {
      id: polygon.polygonId,
      projectId: polygon.id,
      siteId: polygon.siteId,
      name: polygon.polygonName,
      isObstacle: polygon.polygonObstacle,
      paths: this.mapPathsToFE(polygon.paths),
      holes: this.mapHolesToFE(polygon.polygonHoles),
    };
  }

  protected mapHolesToFE(
    paths: Array<Array<any>>,
  ): Array<Array<MapCoordinate>> {
    if (!paths || paths.length === 0) {
      return [[]];
    }

    const holes = [
      ...paths.map((path) =>
        path.map(({ lat, lng }) => ({
          latitude: lat,
          longitude: lng,
        })),
      ),
    ];
    return holes;
  }

  protected mapPathsToFE(paths: Array<any>): Array<MapCoordinate> {
    if (!paths || paths.length === 0) {
      return [];
    }
    return paths.map(({ lat, lng }) => ({
      latitude: lat,
      longitude: lng,
    }));
  }

  protected mapPolygonToBE(polygon: MapPolygon): any {
    return {
      polygonId: polygon.id,
      id: polygon.projectId,
      siteId: polygon.siteId,
      paths: polygon.paths.map((path) => ({
        lat: path.latitude,
        lng: path.longitude,
      })),
      polygonName: polygon.name,
      polygonObstacle: polygon.isObstacle,
    };
  }
}
