import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapLoaderService } from '@multisite-pv/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SiteMapTool } from '../site-map-toolbox';
import { SiteMapGoogleService } from './site-map-google.service';
import { SiteMapPolygonService } from './site-map-polygon.service';
import { MapBounds, MapCoordinate, MapPolygon } from './site-map.model';

export const DEFAULT_SITE_MAP_TOOLS: Array<SiteMapTool<any>> = [
  {
    id: 'select',
    name: 'site.map.toolbox.select',
    icon: 'pan_tool_alt',
    color: '#009de9',
  },
  {
    id: 'polygon',
    name: 'site.map.toolbox.polygon',
    icon: 'hexagon',
    color: '#fbc02d',
  },
  {
    id: 'obstacle',
    name: 'site.map.toolbox.obstacle',
    icon: 'dangerous',
    color: '#cc0033',
  },
];

@UntilDestroy()
@Component({
  selector: 'mpv-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SiteMapPolygonService, SiteMapGoogleService],
})
export class SiteMapComponent implements OnInit {
  map$ = new BehaviorSubject<GoogleMap | undefined>(undefined);
  @ViewChild(GoogleMap, { static: false }) set mapDirective(value: GoogleMap) {
    this.map$.next(value);
  }
  get map() {
    return this.map$.value;
  }

  @Input() height: number | string = 300;
  @Input() width: number | string = '100%';

  polygons$ = new BehaviorSubject<Array<MapPolygon>>([]);
  @Input() set polygons(value: Array<MapPolygon>) {
    this.googleMapService.clearPolygons();
    this.polygons$.next(value);
  }
  get polygons() {
    return this.polygons$.value;
  }

  bounds$ = new BehaviorSubject<MapBounds | undefined>(undefined);
  @Input() set bounds(values: MapBounds | undefined) {
    let considerableBounds: any = [];
    values?.forEach((value) => {
      // The bound is only considered if both bounding lat and lng are found
      if (value && value.latitude && value.longitude) {
        considerableBounds.push(value);
      }
    });

    if (considerableBounds.length < 1) {
      return;
    }

    this.bounds$.next(considerableBounds);
    this.googleMapService.setBounds(this.bounds);
  }
  get bounds() {
    return this.bounds$.value;
  }

  @Input() set center(value: MapCoordinate) {
    if (!value || !value.latitude || !value.longitude) {
      return;
    }

    if (!!this.googleMapService.map) {
      this.googleMapService.map.setCenter(
        this.googleMapService.mapToLatLngLiteral(value),
      );
    } else {
      this.mapOptions = {
        ...this.mapOptions,
        center: this.googleMapService.mapToLatLngLiteral(value),
      };
    }
  }

  editable$ = new BehaviorSubject<boolean>(false);
  @Input() set editable(value: boolean) {
    this.editable$.next(value);
  }

  get editable() {
    return this.editable$.value;
  }

  mapOptions = this.googleMapService.mapOptions;
  apiLoaded$ = this.mapLoader.apiLoaded$;

  tools: Array<SiteMapTool<any>> = DEFAULT_SITE_MAP_TOOLS;

  @Input() set projectId(value: string | undefined) {
    this.googleMapService.setProjectId(value);
  }

  @Input() set siteId(value: string | undefined) {
    this.googleMapService.setSiteId(value);
  }

  @Output() updatedPolygon = new EventEmitter<MapPolygon[]>();
  @Output() polygonSelected = new EventEmitter<string | null>();

  constructor(
    private mapLoader: MapLoaderService,
    private googleMapService: SiteMapGoogleService,
    private polygonService: SiteMapPolygonService,
  ) {}

  ngOnInit(): void {
    this.mapLoader.loadMap().subscribe();

    this.googleMapService.selectedPolygon$
      .pipe(untilDestroyed(this))
      .subscribe((id) => this.polygonSelected.emit(id));

    this.googleMapService.polygonDrawn$
      .pipe(untilDestroyed(this))
      .subscribe((polygon) => this.polygonService.add(polygon));

    this.googleMapService.polygonDrawnUpdated$
      .pipe(untilDestroyed(this))
      .subscribe(({ id, paths }) => this.polygonService.edit(id!, { paths }));

    this.googleMapService.polygonDrawnDeleted$
      .pipe(untilDestroyed(this))
      .subscribe((id) => this.polygonService.delete(id));

    this.polygonService.polygons$
      .pipe(untilDestroyed(this))
      .subscribe((polygons) => this.updatedPolygon.emit(polygons));

    this.polygons$
      .pipe(untilDestroyed(this))
      .subscribe((polygons) => this.drawPolygons(polygons));

    this.editable$.pipe(untilDestroyed(this)).subscribe((editable) => {
      this.setEditMode(editable);
    });

    this.map$
      .pipe(
        filter((map) => !!map?.googleMap),
        untilDestroyed(this),
      )
      .subscribe((map) => {
        this.googleMapService.setMap(map!.googleMap!);
        this.googleMapService.initDrawingManager();
        this.drawPolygons(this.polygons);
        this.setEditMode(this.editable);
        this.googleMapService.setBounds(this.bounds);
      });
  }

  drawPolygons(polygons?: Array<MapPolygon>): void {
    polygons?.forEach((polygon) =>
      this.googleMapService.drawPolygon({
        ...polygon,
        editable: this.editable,
        draggable: this.editable,
      }),
    );
  }

  setEditMode(editable: boolean) {
    this.setEditModeOptions(editable);
    this.googleMapService.showDrawingManager(editable);
  }

  onClickTool(tool: SiteMapTool<any>) {
    switch (tool.id) {
      case 'select':
        this.googleMapService.stopDrawingPolygon();
        break;
      case 'polygon':
        this.googleMapService.startDrawingPolygon(tool);
        break;
      case 'obstacle':
        this.googleMapService.startDrawingPolygon(tool);
        break;
    }
  }

  protected setEditModeOptions(editable: boolean) {
    this.mapOptions = {
      ...this.mapOptions,
      scaleControl: editable,
      panControl: editable,
      zoomControl: editable,
      // fullscreenControl: editable,
      draggable: editable,
      clickableIcons: editable,
    };
  }
}
