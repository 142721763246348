import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { PlotlyGraphComponent } from './plotly-graph.component';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [PlotlyGraphComponent],
  exports: [PlotlyGraphComponent],
  imports: [CommonModule, PlotlyModule, TranslocoModule],
})
export class PlotlyGraphModule {}
