import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { MpvHeaderComponent } from './mpv-header.component';

@NgModule({
  declarations: [MpvHeaderComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [MpvHeaderComponent],
})
export class MpvHeaderModule {}
