<ng-container *transloco="let t; read: 'project'">
  <div fxLayout="row" fxLayoutAlign="end start">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2>{{ t('labels.updateSite') }}</h2>

  <mpv-sites-upload-form></mpv-sites-upload-form>
</ng-container>
