import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UploadFileModule } from '@multisite-pv/shared/components';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';
import { SiteDlTemplateModule } from '../site-dl-template';

import { SitesUploadFormComponent } from './sites-upload-form.component';

@NgModule({
  declarations: [SitesUploadFormComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    ReactiveFormsModule,
    TranslocoModule,
    FlexLayoutModule,
    UploadFileModule,
    MatIconModule,
    MatButtonModule,
    SiteDlTemplateModule,
  ],
  exports: [SitesUploadFormComponent],
})
export class SitesUploadFormModule {}
