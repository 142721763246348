import { BehaviorSubject } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Project } from '@multisite-pv/+project/project.model';
import { ProjectRepository } from '@multisite-pv/+project/store';

@Component({
  selector: 'mpv-site-import-dialog',
  templateUrl: './site-import-dialog.component.html',
  styleUrls: ['./site-import-dialog.component.scss'],
})
export class SiteImportDialogComponent implements OnInit {
  selectedProject$ = new BehaviorSubject<any>(undefined);
  get selectedProject(): Project {
    return this.selectedProject$.value;
  }
  constructor(
    public dialogRef: MatDialogRef<SiteImportDialogComponent>,
    private projectRepository: ProjectRepository,
  ) {}
  ngOnInit(): void {
    this.projectRepository.selectedEntity$.subscribe((project) => {
      this.selectedProject$.next(project);

      if (project?.uploading) {
        this.dialogRef.close();
      }
    });
  }
  onClose() {
    this.dialogRef.close();
  }
}
