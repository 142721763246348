import { BehaviorSubject } from 'rxjs';

import { Component, Input } from '@angular/core';

import { PlotlyData } from './plotly-graph.model';

@Component({
  selector: 'mpv-plotly-graph',
  templateUrl: './plotly-graph.component.html',
  styleUrls: ['./plotly-graph.component.scss'],
})
export class PlotlyGraphComponent {
  @Input() set profiles(profiles: PlotlyData[]) {
    if (profiles?.length) {
      this.data$.next(profiles);
      this.initializeLayout();
    }
  }
  @Input() title: string = '';
  startDate: string = '';
  endDate: string = '';
  layout: any;
  data$ = new BehaviorSubject<PlotlyData[]>([]);
  get data() {
    return this.data$.value;
  }

  constructor() {}

  initializeLayout() {
    if (this.data?.length) {
      const dates = this.data[0]?.x;
      if (dates?.length) {
        this.startDate = dates[0];
        this.endDate = dates[dates.length - 1];
      }
    }

    this.layout = {
      title: this.title,
      showlegend: true,
      xaxis: {
        hoverformat: '%b %d, %Y %H:%M',
        autorange: true,
        range: [this.startDate, this.endDate],
        rangeselector: {
          buttons: [
            {
              count: 2,
              label: '2d',
              step: 'day',
            },
            {
              count: 1,
              label: '1m',
              step: 'month',
            },
            {
              count: 3,
              label: '3m',
              step: 'month',
            },
            {
              count: 6,
              label: '6m',
              step: 'month',
            },
            { step: 'all' },
          ],
        },
        rangeslider: { range: [this.startDate, this.endDate] },
        type: 'date',
      },
      yaxis: {
        autorange: true,
        type: 'linear',
      },
      width: 900,
    };
  }
}
