<mat-button-toggle-group
  *transloco="let t"
  aria-label="Map Toolbox"
  data-testid="toolbox"
  name="toolbox"
  vertical="true"
  [value]="selected"
>
  <mat-button-toggle
    *ngFor="let tool of tools"
    data-testid="tool"
    mat-icon-button
    matTooltipPosition="right"
    [value]="tool"
    [style.color]="tool.color"
    [attr.aria-label]="t(tool.name)"
    [matTooltip]="t(tool.name)"
    (click)="onSelectTool(tool)"
  >
    <mat-icon [attr.color]="tool.color">{{ tool.icon }}</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
