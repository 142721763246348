import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
} from '@angular/core';
import {
  TranslocoScope,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import tippy, { Props } from 'tippy.js';

const TRANSLOCO_SCOPE_KEY = 'helpy';

@UntilDestroy()
@Component({
  selector: 'mpv-helpy',
  templateUrl: './helpy.component.html',
  styleUrls: ['./helpy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: TRANSLOCO_SCOPE_KEY,
    },
  ],
})
export class HelpyComponent {
  private readonly keyCache = new BehaviorSubject<string>('');
  @Input() set key(key: string) {
    this.keyCache.next(key);
  }
  tippyInstance = tippy(this.elementRef.nativeElement, this.getTippyProps());

  constructor(
    private readonly elementRef: ElementRef,
    private readonly transloco: TranslocoService,
    @Inject(TRANSLOCO_SCOPE) private readonly scope: TranslocoScope,
  ) {
    this.subscribeToKeyCacheForTippyInit();
  }

  /* istanbul ignore next */
  getTippyProps(): Partial<Props> {
    return {
      interactive: true,
      appendTo: () => document.body,
      theme: 'light',
    };
  }

  private subscribeToKeyCacheForTippyInit(): void {
    this.keyCache
      .pipe(
        mergeMap((key) => this.selectTranslation(key)),
        untilDestroyed(this),
      )
      .subscribe((key) => this.setContent(key));
  }

  private selectTranslation(key: string): Observable<string> {
    return this.transloco.selectTranslate(key, {}, this.scope);
  }

  private setContent(content: string): void {
    (this.tippyInstance as any).setContent(content);
  }
}
