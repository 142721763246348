import { Observable, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SiteRepository } from '@multisite-pv/+site/store';

@Injectable({
  providedIn: 'root',
})
export class SitePageResolver implements Resolve<boolean> {
  constructor(private siteRepository: SiteRepository) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { projectId, siteId } = route.params;

    this.siteRepository.getSite(projectId, siteId);
    this.siteRepository.selectEntity(siteId);
    this.siteRepository.getPolygons(projectId, siteId);
    this.siteRepository.selectedEntity$
      .pipe(
        filter((site) => !!site?.productionKey || !!site?.consumptionKey),
        first(),
      )
      .subscribe((selectedSite) => {
        if (!!selectedSite?.productionKey) {
          this.siteRepository.getProfiles(projectId, siteId, 'production');
        }

        if (!!selectedSite?.consumptionKey) {
          this.siteRepository.getProfiles(projectId, siteId, 'consumption');
        }
      });
    return of(true);
  }
}
