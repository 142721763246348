import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { MapPolygon } from './site-map.model';

@Injectable()
export class SiteMapPolygonService {
  protected _polygons$ = new BehaviorSubject<Array<MapPolygon>>([]);
  polygons$ = this._polygons$.asObservable();

  get polygons() {
    return this._polygons$.value;
  }

  set polygons(value: Array<MapPolygon>) {
    this._polygons$.next(value);
  }

  add(polygon: MapPolygon) {
    this._polygons$.next([...this.polygons, polygon]);
  }

  edit(id: string, changes: Partial<MapPolygon>) {
    this._polygons$.next([
      ...this.polygons.filter((polygon) => polygon.id !== id),
      {
        ...this.polygons.find((polygon) => polygon.id === id),
        ...(changes as MapPolygon),
      },
    ]);
  }

  delete(id: string) {
    this._polygons$.next(this.polygons.filter((polygon) => polygon.id !== id));
  }
}
