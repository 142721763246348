import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { SiteDlTemplateComponent } from './site-dl-template.component';

@NgModule({
  declarations: [SiteDlTemplateComponent],
  imports: [CommonModule, FlexLayoutModule, TranslocoModule],
  exports: [SiteDlTemplateComponent],
})
export class SiteDlTemplateModule {}
