import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as generateUuid } from 'uuid';

import { Injectable } from '@angular/core';

import { SiteMapTool } from '../site-map-toolbox';
import { MapBounds, MapCoordinate, MapPolygon } from './site-map.model';

export const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  tilt: 0,
  zoom: 17.3,
  mapTypeId: 'satellite',
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  panControl: false,
  zoomControl: false,
  fullscreenControl: false,
  draggable: false,
  clickableIcons: false,
  controlSize: 28,
  disableDoubleClickZoom: true,
};

export const DEFAULT_POLYGON_OPTIONS: google.maps.PolygonOptions = {
  strokeColor: '#fbc02d',
  strokeWeight: 3,
  fillColor: '#fbc02d',
  fillOpacity: 0.7,
};

export const DEFAULT_OBSTACLE_OPTIONS: google.maps.PolygonOptions = {
  ...DEFAULT_POLYGON_OPTIONS,
  fillColor: '#cc0033',
  strokeColor: '#cc0033',
  zIndex: 10,
};

type MapListeners = Readonly<{
  remove_at: Map<string, google.maps.MapsEventListener>;
  insert_at: Map<string, google.maps.MapsEventListener>;
  set_at: Map<string, google.maps.MapsEventListener>;
  dragstart: Map<string, google.maps.MapsEventListener>;
  dragend: Map<string, google.maps.MapsEventListener>;
  click: Map<string, google.maps.MapsEventListener>;
}>;

@Injectable()
export class SiteMapGoogleService {
  protected listeners: MapListeners = {
    remove_at: new Map<string, google.maps.MapsEventListener>(),
    insert_at: new Map<string, google.maps.MapsEventListener>(),
    set_at: new Map<string, google.maps.MapsEventListener>(),
    dragstart: new Map<string, google.maps.MapsEventListener>(),
    dragend: new Map<string, google.maps.MapsEventListener>(),
    click: new Map<string, google.maps.MapsEventListener>(),
  };

  mapOptions: google.maps.MapOptions = DEFAULT_MAP_OPTIONS;
  polygonOptions: google.maps.PolygonOptions = DEFAULT_POLYGON_OPTIONS;
  obstacleOptions: google.maps.PolygonOptions = DEFAULT_OBSTACLE_OPTIONS;

  drawingManager: google.maps.drawing.DrawingManager | undefined;
  map: google.maps.Map | undefined;

  polygonDrawn$ = new Subject<MapPolygon>();
  polygonDrawnUpdated$ = new Subject<Partial<MapPolygon>>();
  polygonDrawnDeleted$ = new Subject<string>();

  isDrawingObstacle = false;

  protected _projectId?: string;
  protected _siteId?: string;

  get projectId() {
    return this._projectId;
  }

  get siteId() {
    return this._siteId;
  }

  protected polygons = new Map<string, google.maps.Polygon>();

  protected _selectedPolygon = new BehaviorSubject<string | null>(null);
  selectedPolygon$ = this._selectedPolygon.asObservable();
  set selectedPolygon(value: string | null) {
    this._selectedPolygon.next(value);
  }
  get selectedPolygon() {
    return this._selectedPolygon.value;
  }

  setMap(map: google.maps.Map) {
    this.map = map;

    this.map?.addListener('click', () => {
      this.unselectPolygon();
    });

    document.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        this.deletePolygon(this.selectedPolygon);
      }
    });
  }

  initDrawingManager(): void {
    if (!this.map) {
      return;
    }

    const drawingOptions: google.maps.drawing.DrawingManagerOptions = {
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing?.OverlayType?.POLYGON],
      },
      polygonOptions: {
        ...this.polygonOptions,
        clickable: true,
      },
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(
      drawingOptions,
    );

    this.drawingManager.addListener(
      'polygoncomplete',
      (gPolygon: google.maps.Polygon) => {
        this.unselectPolygon();
        this.notifyAddedPolygon(
          this.mapToPolygon(this.generateId(), gPolygon),
          gPolygon,
        );
      },
    );
  }

  showDrawingManager(show: boolean) {
    show
      ? this.drawingManager?.setMap(this.map || null)
      : this.drawingManager?.setMap(null);
  }

  isPolygonExists(id: string) {
    return !!this.getPolygon(id);
  }

  deletePolygon(id: string | null) {
    if (!id) {
      return;
    }

    if (this.selectedPolygon === id) {
      this.unselectPolygon();
    }

    this.getPolygon(id)?.setMap(null);
    this.polygons.delete(id);
    this.polygonDrawnDeleted$.next(id);
    this.clearAllPolygonListeners(id);
  }

  selectPolygon(id: string) {
    if (id === this.selectedPolygon) {
      return;
    }

    const selected = this.getPolygon(id);
    selected?.setEditable(true);
    selected?.setDraggable(true);
    this.selectedPolygon = id;
  }

  unselectPolygon() {
    if (!!this.selectedPolygon) {
      const selected = this.getPolygon(this.selectedPolygon);
      selected?.setEditable(false);
      selected?.setDraggable(false);
      this.selectedPolygon = null;
    }
  }

  drawPolygon(polygon: MapPolygon): void {
    if (!this.map || !polygon) {
      return;
    }

    const gPolygon = new google.maps.Polygon();
    this.checkPolygonObstacles(polygon, gPolygon);
    this.checkPolygonHoles(polygon, gPolygon);

    gPolygon.setMap(this.map);

    this.notifyAddedPolygon(polygon, gPolygon);
  }

  setBounds(bounds: MapBounds | undefined): void {
    if (!this.map || !bounds || !bounds[0] || !bounds[1]) {
      return;
    }
    const gBounds = new google.maps.LatLngBounds(
      this.mapToLatLngLiteral(bounds[0]),
      this.mapToLatLngLiteral(bounds[1]),
    );
    this.map.fitBounds(gBounds);
  }

  mapToLatLngLiteral(coordinate: MapCoordinate): google.maps.LatLngLiteral {
    return {
      lat: coordinate.latitude,
      lng: coordinate.longitude,
    };
  }

  mapToCoordinate(
    latLng: google.maps.LatLngLiteral | google.maps.LatLng,
  ): MapCoordinate {
    if (latLng instanceof google.maps.LatLng) {
      return {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      };
    }
    return {
      latitude: latLng.lat,
      longitude: latLng.lng,
    };
  }

  addPolygonListeners(id: string, gPolygon: google.maps.Polygon) {
    if (!id || !gPolygon) {
      return;
    }

    this.addDragPolygonListener(id, 'dragstart', gPolygon);
    this.addDragPolygonListener(id, 'dragend', gPolygon);
    this.addEditPolygonListener(id, 'insert_at', gPolygon.getPath());
    this.addEditPolygonListener(id, 'remove_at', gPolygon.getPath());
    this.addEditPolygonListener(id, 'set_at', gPolygon.getPath());
    this.addClickPolygonListener(id, gPolygon);
  }

  setProjectId(projectId: string | undefined) {
    this._projectId = projectId;
  }

  setSiteId(siteId: string | undefined) {
    this._siteId = siteId;
  }

  startDrawingPolygon(option?: SiteMapTool) {
    this.drawingManager?.setDrawingMode(
      google.maps.drawing?.OverlayType?.POLYGON,
    );
    this.isDrawingObstacle = option?.id === 'obstacle' || false;
    this.drawingManager?.setOptions({
      polygonOptions: {
        ...DEFAULT_POLYGON_OPTIONS,
        strokeColor: option?.color,
        fillColor: option?.color,
      },
    });
  }

  stopDrawingPolygon() {
    this.drawingManager?.setDrawingMode(null);
  }

  clearPolygons() {
    this.polygons.forEach((polygon) => {
      polygon.setMap(null);
    });
    this.polygons.clear();
  }

  getPolygonsCount() {
    return this.polygons.size;
  }

  protected addClickPolygonListener(id: string, gPolygon: google.maps.Polygon) {
    this.listeners['click'].set(
      id,
      gPolygon?.addListener('click', () => {
        if (this.selectedPolygon !== id) {
          this.unselectPolygon();
        }
        this.selectPolygon(id);
      }),
    );
  }

  protected addDragPolygonListener(
    id: string,
    eventName: 'dragstart' | 'dragend',
    gPolygon: google.maps.Polygon,
  ): void {
    if (!id || !eventName || !gPolygon) {
      return;
    }

    this.listeners[eventName].set(
      id,
      gPolygon.addListener(eventName, () => {
        if (eventName === 'dragstart') {
          this.clearPolygonListener(id, 'set_at');
          return;
        }

        if (eventName === 'dragend') {
          this.addEditPolygonListener(id, 'set_at', gPolygon.getPath());
        }

        this.polygonDrawnUpdated$.next({
          id,
          paths: gPolygon.getPath().getArray().map(this.mapToCoordinate),
        });
      }),
    );
  }

  protected addEditPolygonListener(
    id: string,
    eventName: 'insert_at' | 'remove_at' | 'set_at',
    gPathsArray: google.maps.MVCArray<google.maps.LatLng>,
  ): void {
    if (!id || !eventName || !gPathsArray) {
      return;
    }

    this.listeners[eventName].set(
      id,
      gPathsArray.addListener(eventName, () => {
        this.polygonDrawnUpdated$.next({
          id,
          paths: gPathsArray.getArray().map(this.mapToCoordinate),
        });
      }),
    );
  }

  protected clearAllPolygonListeners(id: string) {
    Object.keys(this.listeners).forEach((eventName) =>
      this.clearPolygonListener(id, eventName as keyof MapListeners),
    );
  }

  protected clearPolygonListener(id: string, eventName: keyof MapListeners) {
    this.listeners[eventName].get(id)?.remove;
    this.listeners[eventName].delete(id);
  }

  protected checkPolygonObstacles(
    polygon: MapPolygon,
    gPolygon: google.maps.Polygon,
  ): void {
    polygon.isObstacle
      ? this.applyObstacleOptions(gPolygon, polygon.draggable)
      : this.applyPolygonOptions(gPolygon, polygon.draggable);
  }

  protected checkPolygonHoles(
    polygon: MapPolygon,
    gPolygon: google.maps.Polygon,
  ): void {
    !!polygon.holes
      ? this.applyPolygonWithHolePaths(polygon, gPolygon)
      : this.applyPolygonPaths(polygon, gPolygon);
  }

  protected applyPolygonOptions(
    gPolygon: google.maps.Polygon,
    clickable: boolean = false,
  ): void {
    gPolygon.setOptions({ ...this.polygonOptions, clickable });
  }

  protected applyObstacleOptions(
    gPolygon: google.maps.Polygon,
    clickable: boolean = false,
  ): void {
    gPolygon.setOptions({
      ...this.polygonOptions,
      ...this.obstacleOptions,
      clickable,
    });
  }

  protected applyPolygonWithHolePaths(
    polygon: MapPolygon,
    gPolygon: google.maps.Polygon,
  ): void {
    gPolygon.setPaths([
      polygon.paths.map(this.mapToLatLngLiteral),
      ...polygon.holes!.map((hole) => hole.map(this.mapToLatLngLiteral)),
    ]);
  }

  protected applyPolygonPaths(
    polygon: MapPolygon,
    gPolygon: google.maps.Polygon,
  ): void {
    gPolygon.setPaths(polygon.paths.map(this.mapToLatLngLiteral));
  }

  protected getPolygon(id: string) {
    return this.polygons.get(id);
  }

  protected addPolygon(id: string, gPolygon: google.maps.Polygon): void {
    this.polygons.set(id, gPolygon);
  }

  protected generateId() {
    return generateUuid();
  }

  protected notifyAddedPolygon(
    polygon: MapPolygon | undefined,
    gPolygon: google.maps.Polygon | undefined,
  ) {
    if (!polygon || !gPolygon) {
      return;
    }

    this.addPolygon(polygon.id, gPolygon);
    this.polygonDrawn$.next(polygon);
    this.addPolygonListeners(polygon.id, gPolygon);
  }

  protected mapToPolygon(
    id: string,
    polygon: google.maps.Polygon,
  ): MapPolygon | undefined {
    if (!id || !polygon) {
      return undefined;
    }

    const paths: Array<MapCoordinate> = [];
    polygon.getPath().forEach((path) => {
      paths.push(this.mapToCoordinate(path));
    });

    return {
      id,
      projectId: this._projectId!,
      siteId: this._siteId!,
      paths,
      isObstacle: this.isDrawingObstacle,
    };
  }
}
