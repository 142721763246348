import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveComponentModule } from '@ngrx/component';
import { SiteProposalModule } from '../site-proposal';
import { SiteImpressionComponent } from './site-impression.component';

@NgModule({
  declarations: [SiteImpressionComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatIconModule,
    SiteProposalModule,
    FlexLayoutModule,
    ReactiveComponentModule,
  ],
  exports: [SiteImpressionComponent],
})
export class SiteImpressionModule {}
