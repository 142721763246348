export const REFERENCE: Record<string, any> = {
  failed: {
    icon: 'error',
    color: 'warn',
    label: 'failedImport',
    descriptions: 'failedImportMessage',
  },
  success: {
    icon: 'cloud_upload',
    color: 'primary',
    label: 'dragDropFile',
    descriptions: 'dragDropSubLabel',
  },
  ongoing: {
    icon: 'hourglass_bottom',
    color: 'primary',
    label: 'uploadingSite',
    descriptions: 'uploadingSiteDescription',
  },
  default: {
    icon: 'cloud_upload',
    color: 'primary',
    label: 'dragDropFile',
    descriptions: 'dragDropSubLabel',
  },
};
