import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MAP_CONFIG, MapConfig } from '@multisite-pv/environment.token';

@Injectable({
  providedIn: 'root',
})
export class MapLoaderService {
  protected apiLoaded = new BehaviorSubject<boolean>(false);
  apiLoaded$ = this.apiLoaded.asObservable();

  constructor(
    @Inject(MAP_CONFIG) private config: MapConfig,
    private http: HttpClient,
  ) {}

  get loaded() {
    return this.apiLoaded.value;
  }

  set loaded(value: boolean) {
    this.apiLoaded.next(value);
  }

  loadMap() {
    if (this.loaded) {
      return of(true);
    }

    return this.http
      .jsonp(
        `${this.config.api}?key=${this.config.apiKey}&libraries=drawing`,
        'callback',
      )
      .pipe(
        map(() => true),
        tap((loaded) => (this.loaded = loaded)),
        catchError((error) => {
          this.loaded = false;
          return throwError(error);
        }),
      );
  }
}
