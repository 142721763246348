import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Project } from '@multisite-pv/+project';

import { SiteRepository } from '../store';

@Component({
  selector: 'mpv-sites-upload-form',
  templateUrl: './sites-upload-form.component.html',
  styleUrls: ['./sites-upload-form.component.scss'],
})
export class SitesUploadFormComponent implements OnInit {
  @Input() set selectedProject(project: Project) {
    if (!!project) {
      this.selectedProject$.next(project);
    }
  }
  selectedProject$ = new BehaviorSubject<any>({});
  get project() {
    return this.selectedProject$.value;
  }
  sitesForm = new FormControl('', Validators.required);

  constructor(private siteRepository: SiteRepository) {}
  ngOnInit(): void {
    this.sitesForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (this.sitesForm.valid) {
          this.siteRepository.uploadSites(this.project?.id, value);
          this.sitesForm.reset();
        }
      });
  }
}
