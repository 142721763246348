function object<T>(alleged: T | null | undefined): T {
  return alleged || ({} as T);
}

function array<T>(alleged: T[] | null | undefined): T[] {
  return alleged || ([] as T[]);
}

function string(alleged: string | null | undefined): string {
  return alleged || '';
}

export const Coerce = {
  object,
  array,
  string,
};
