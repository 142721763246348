import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  ControlsOf,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SiteLocation } from './site-location-form.model';

@UntilDestroy()
@Component({
  selector: 'mpv-site-location-form',
  templateUrl: './site-location-form.component.html',
  styleUrls: ['./site-location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteLocationFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SiteLocationFormComponent),
      multi: true,
    },
  ],
})
export class SiteLocationFormComponent
  extends ControlValueAccessor<SiteLocation>
  implements OnInit, Validator
{
  locationForm: FormGroup<ControlsOf<SiteLocation>>;

  get country() {
    return this.locationForm.get('country');
  }

  get address() {
    return this.locationForm.get('address');
  }

  get latitude() {
    return this.locationForm.get('latitude');
  }

  get longitude() {
    return this.locationForm.get('longitude');
  }

  constructor() {
    super();

    this.locationForm = new FormGroup<ControlsOf<SiteLocation>>({
      country: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      latitude: new FormControl(null, Validators.required),
      longitude: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.locationForm.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChange!(value));
  }

  writeValue(value: SiteLocation): void {
    this.locationForm.patchValue(value);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.locationForm.invalid ? { invalid: true } : null;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.locationForm.disable() : this.locationForm.enable();
  }
}
