import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { ProposalRepo, SiteRepository } from '../store';

export enum ImpressionMode {
  MAP = 'map',
  PROPOSAL = 'proposal',
}

@UntilDestroy()
@Component({
  selector: 'mpv-site-impression',
  templateUrl: './site-impression.component.html',
  styleUrls: ['./site-impression.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteImpressionComponent {
  readonly selectedImpression = new FormControl<ImpressionMode>(
    ImpressionMode.MAP,
  );
  readonly hasProposal$ = this.selectHasProposal();

  constructor(
    private readonly proposalRepo: ProposalRepo,
    private readonly siteRepo: SiteRepository,
  ) {
    this.subscribeToActiveSiteForSelectedImpressionResetting();
  }

  private subscribeToActiveSiteForSelectedImpressionResetting(): void {
    this.selectActiveSiteId()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.selectedImpression.patchValue(ImpressionMode.MAP));
  }

  private selectActiveSiteId(): Observable<string> {
    return this.siteRepo.selectedEntity$.pipe(pluck('id'));
  }

  private selectHasProposal(): Observable<boolean> {
    return this.proposalRepo.selectActiveSiteHasProposal();
  }
}
