<form
  *transloco="let t; read: 'site.location'"
  [formGroup]="locationForm"
  data-testid="location"
  fxLayout="column"
  fxLayoutGap="0.5rem"
>
  <mat-form-field class="mpv-full-max-width">
    <mat-label>{{ t('country.label') }}</mat-label>
    <input matInput formControlName="country" data-testid="country" required />
    <mpv-field-suffix
      matSuffix
      data-test="countrySuffix"
      helpyKey="country"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="country?.errors?.required"
      data-testid="country-required"
      >{{ t('country.messages.required') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field class="mpv-full-max-width">
    <mat-label>{{ t('address.label') }}</mat-label>
    <textarea
      matInput
      formControlName="address"
      data-testid="address"
      required
    ></textarea>
    <mpv-field-suffix
      matSuffix
      data-test="addressSuffix"
      helpyKey="address"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="address?.errors?.required"
      data-testid="address-required"
      >{{ t('address.messages.required') }}</mat-error
    >
  </mat-form-field>

  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="0.5rem"
    fxLayoutGap.gt-sm="1rem"
  >
    <mat-form-field fxFlex>
      <mat-label>{{ t('latitude.label') }}</mat-label>
      <input
        matInput
        formControlName="latitude"
        data-testid="latitude"
        type="number"
        required
      />
      <mpv-field-suffix
        matSuffix
        data-test="latitudeSuffix"
        helpyKey="latitude"
        [unit]="t('latitude.unit')"
      ></mpv-field-suffix>
      <mat-error
        *ngIf="latitude?.errors?.required"
        data-testid="latitude-required"
        >{{ t('latitude.messages.required') }}</mat-error
      >
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ t('longitude.label') }}</mat-label>
      <input
        matInput
        formControlName="longitude"
        data-testid="longitude"
        type="number"
        required
      />
      <mpv-field-suffix
        matSuffix
        data-test="longitudeSuffix"
        helpyKey="longitude"
        [unit]="t('longitude.unit')"
      ></mpv-field-suffix>
      <mat-error
        *ngIf="longitude?.errors?.required"
        data-testid="longitude-required"
        >{{ t('longitude.messages.required') }}</mat-error
      >
    </mat-form-field>
  </div>
</form>
