import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LOADER_CSS } from '@multisite-pv/+project/project.constant';
import { Project } from '@multisite-pv/+project/project.model';
import { SiteRepository } from '@multisite-pv/+site/store';
import { STATUS } from '@multisite-pv/app.reference';

import { Site } from '../site.model';

type SiteProp = keyof Site;

@Component({
  selector: 'mpv-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss'],
})
export class SiteListComponent implements OnInit, AfterViewInit {
  @Input() set selectedProject(project: Project | undefined) {
    this.selectedProject$.next(project);
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  loaderCss = LOADER_CSS;
  readonly columnsWithoutAction: SiteProp[] = ['country', 'address'];
  readonly displayedColumns = ['identifier', ...this.columnsWithoutAction];
  dataSource = new MatTableDataSource<Site>([]);
  selectedProject$ = new BehaviorSubject<any>({});
  hasData$ = new BehaviorSubject<boolean>(false);
  isOnImport$ = new BehaviorSubject<boolean>(false);
  loading$ = this.siteRepository.loading$;
  get project() {
    return this.selectedProject$.value;
  }

  get isOnImport() {
    return this.isOnImport$.value;
  }
  constructor(
    private siteRepository: SiteRepository,
    public dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.initializeSites();
    this.initializeDialogHandling();
  }

  initializeSites() {
    this.siteRepository.filterSites$.subscribe((sites) => {
      if (sites.length) {
        this.dataSource.data = sites;
        this.hasData$.next(true);
      }
    });
    this.loading$.pipe(filter((laoding) => !!laoding)).subscribe((loading) => {
      this.dataSource.data = [];
    });
  }

  initializeDialogHandling() {
    combineLatest([this.selectedProject$, this.hasData$])
      .pipe(filter(([_, hasData]) => hasData))
      .subscribe(([project, _]) => {
        if (project.uploading) {
          this.isOnImport$.next(true);
        } else if (project.status === STATUS.SUCCESS) {
          this.isOnImport$.next(false);
        }
      });
  }

  onImport() {
    this.isOnImport$.next(!this.isOnImport);
  }

  onSelect(site: Site) {
    this.router.navigate(['sites', site.id], {
      relativeTo: this.activeRoute,
    });
  }

  onExport() {
    this.siteRepository.exportSites(this.project.id);
  }
}
