<ng-container *transloco="let t; read: 'site'">
  <mat-icon
    *ngIf="short; else long"
    data-test="short"
    class="site-status-short"
    [ngClass]="'short-' + status"
    [matTooltip]="t('statusNames.' + status) | uppercase"
    [inline]="true"
  >
    {{ t('statusIcon.' + status) }}
  </mat-icon>

  <ng-template #long>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      class="site-status-div"
      [ngClass]="'site-status-' + status"
    >
      <mat-icon data-test="icon" [inline]="true">
        {{ t('statusIcon.' + status) }}
      </mat-icon>
      <span *ngIf="status" data-test="name">
        {{ t('statusNames.' + status) | uppercase }}
      </span>
    </div>
  </ng-template>
</ng-container>
