import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveComponentModule } from '@ngrx/component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SiteProposalComponent } from './site-proposal.component';

@NgModule({
  declarations: [SiteProposalComponent],
  imports: [CommonModule, IvyCarouselModule, ReactiveComponentModule],
  exports: [SiteProposalComponent],
})
export class SiteProposalModule {}
