import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  MapBounds,
  MapCoordinate,
  MapPolygon,
} from '../site-map/site-map.model';
import { SiteMapDialogData } from './site-map-dialog.model';

@Component({
  selector: 'mpv-site-map-dialog',
  templateUrl: './site-map-dialog.component.html',
  styleUrls: ['./site-map-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMapDialogComponent {
  polygons: Array<MapPolygon>;
  updatedPolygons: Array<MapPolygon> = [];
  center: MapCoordinate;
  projectId?: string;
  siteId?: string;
  bounds?: MapBounds;

  protected selected = new BehaviorSubject<string | null>(null);
  selected$ = this.selected.pipe(map((value) => !!value));

  constructor(
    private dialogRef: MatDialogRef<SiteMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SiteMapDialogData,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.polygons = this.data.polygons;
    this.center = this.data.center;
    this.projectId = this.data.projectId;
    this.siteId = this.data.siteId;
    this.bounds = this.data.bounds;
  }

  onSave(): void {
    this.dialogRef.close(this.updatedPolygons);
  }

  updatePolygons(polygon: Array<MapPolygon>) {
    this.updatedPolygons = polygon;
  }

  onSelectPolygon(id: string | null) {
    this.selected.next(id);
    this.changeDetector.detectChanges();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
