export type ProjectAndSiteId = string;
export type ProposalImageUrl = string;

export interface ProposalState {
  readonly proposals: Record<ProjectAndSiteId, ProposalImageUrl[]>;
}

export const initializeState = (): ProposalState => ({ proposals: {} });

export interface GetProposedPVImagesRsp {
  readonly urls: string[];
}
