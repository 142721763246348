import { BehaviorSubject } from 'rxjs';

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Project } from '@multisite-pv/+project';

@Component({
  selector: 'mpv-header',
  templateUrl: './mpv-header.component.html',
  styleUrls: ['./mpv-header.component.scss'],
})
export class MpvHeaderComponent {
  project$ = new BehaviorSubject<any>(undefined);

  @Input() set project(value: Project) {
    if (value) {
      this.project$.next(value);
    }
  }
  get project() {
    return this.project$.value;
  }

  constructor(private router: Router) {}

  onClick(): void {
    this.router.navigate(['projects', this.project.id]);
  }
}
