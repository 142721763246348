import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ContentHeaderModule } from '@multisite-pv/shared/components/content-header';
import { MessageDialogModule } from '@multisite-pv/shared/components/message-dialog';
import { PageContentModule } from '@multisite-pv/shared/components/page-content';
import { SectionHeaderModule } from '@multisite-pv/shared/components/section-header';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';

import { SiteEconomicsFormModule } from './site-economics-form';
import { SiteImpressionModule } from './site-impression';
import { SiteLayoutFormModule } from './site-layout-form';
import { SiteLocationFormModule } from './site-location-form';
import { SiteMapModule } from './site-map';
import { SiteMapDialogModule } from './site-map-dialog';
import { SitePageComponent } from './site-page.component';
import { SiteProfileGraphModule } from './site-profile-graph';
import { SiteRoutingModule } from './site-routing.module';
import { SiteSavingsFormModule } from './site-savings-form';
import { SiteSolarPotentialFormModule } from './site-solar-potential-form';
import { SiteSurfaceAnalysisFormModule } from './site-surface-analysis-form';

@NgModule({
  declarations: [SitePageComponent],
  imports: [
    SiteRoutingModule,
    CommonModule,
    ReactiveComponentModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslocoModule,
    PageContentModule,
    ContentHeaderModule,
    SectionHeaderModule,
    SiteLocationFormModule,
    SiteLayoutFormModule,
    SiteSolarPotentialFormModule,
    SiteSurfaceAnalysisFormModule,
    SiteEconomicsFormModule,
    SiteSavingsFormModule,
    SiteMapModule,
    SiteMapDialogModule,
    MessageDialogModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    NgxSkeletonLoaderModule,
    SiteProfileGraphModule,
    SiteImpressionModule,
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'site' }],
})
export class SiteModule {}
