import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Image } from 'angular-responsive-carousel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProposalRepo } from '../store';

@Component({
  selector: 'mpv-site-proposal',
  templateUrl: './site-proposal.component.html',
  styleUrls: ['./site-proposal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteProposalComponent {
  readonly proposalImages$: Observable<Image[]> = this.selectProposalImgs();

  constructor(private readonly proposals: ProposalRepo) {}

  private selectProposalImgs(): Observable<Image[]> {
    return this.proposals
      .selectActiveSiteProposal()
      .pipe(map((urls) => this.urlsToImages(urls)));
  }

  private urlsToImages(urls: string[]): Image[] {
    return urls.map((url) => ({ path: url }));
  }
}
