import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, pluck, skipWhile } from 'rxjs/operators';

import { Component, Input } from '@angular/core';

import { Site } from '../site.model';

@Component({
  selector: 'mpv-site-profile-graph',
  templateUrl: './site-profile-graph.component.html',
  styleUrls: ['./site-profile-graph.component.scss'],
})
export class SiteProfileGraphComponent {
  @Input() set selectedSite(site: Site) {
    this.selectedSite$.next(site);
  }
  selectedSite$ = new BehaviorSubject<any>({});

  production$ = this.selectedSite$.pipe(pluck('production'));
  consumption$ = this.selectedSite$.pipe(pluck('consumption'));
  loading$ = this.selectedSite$.pipe(
    map((site) => site?.productionLoading || site?.consumptionLoading),
  );
  data$ = combineLatest([this.production$, this.consumption$]).pipe(
    skipWhile(([production, consumption]) => !!!production && !!!consumption),
    map(([production, consumption]) => [
      {
        type: 'scatter',
        mode: 'lines',
        name: 'Production',
        x: !!production ? Object.keys(production) : [],
        y: !!production ? Object.values(production) : [],
        line: { color: '#17BECF' },
      },
      {
        type: 'scatter',
        mode: 'lines',
        name: 'Consumption',
        x: !!consumption ? Object.keys(consumption) : [],
        y: !!consumption ? Object.values(consumption) : [],
        line: { color: '#7F7F7F' },
      },
    ]),
  );

  loadingConfig = {
    width: '800px',
    height: '300px',
  };
  constructor() {}
}
