<div fxLayout="column" fxLayoutAlign="center center">
  <mat-icon class="generic-error-icon" color="accent">{{ icon }}</mat-icon>
  <h1 class="generic-error-title">{{ title }}</h1>
  <p class="mat-hint generic-error-message">{{ message }}</p>

  <button
    mat-flat-button
    class="generic-error-action-button"
    color="primary"
    (click)="onClickAction()"
  >
    <div flexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.25rem">
      <mat-icon class="generic-error-action-button-icon">{{
        actionIcon
      }}</mat-icon>

      <span class="generic-error-action-button-label">{{
        actionLabel | uppercase
      }}</span>
    </div>
  </button>
</div>
