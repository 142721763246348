import { State } from '@multisite-pv/shared/model';
import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

import { Project } from '../project.model';

export const projectStore = createStore(
  { name: 'project' },
  withProps<State>({ loaded: false, loading: false }),
  withEntities<Project>(),
  withActiveId(),
);
projectStore.getValue();
export type ProjectState = typeof projectStore.state;
