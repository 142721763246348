import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mpv-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentHeaderComponent {
  @Input() title?: string;
  @Input() description?: string;
  @Input() status: string | undefined;

  private _loading: boolean | string = false;
  @Input() set loading(value: boolean | string) {
    this._loading = coerceBooleanProperty(value);
  }
  get loading(): boolean | string {
    return this._loading;
  }

  loaderConfig = {
    title: {
      height: '16px',
      margin: '1rem 0 1.5rem',
      'min-width': '200px',
      'max-width': '200px',
    },
    description: {
      height: '12px',
      'min-width': '400px',
    },
  };
}
