import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Coerce } from '@multisite-pv/shared/utils';
import { of } from 'rxjs';
import { filter, take, timeoutWith } from 'rxjs/operators';
import { ProposalRepo } from './store';

@Injectable({
  providedIn: 'root',
})
export class SiteProposalResolver implements Resolve<void> {
  constructor(private readonly proposalRepo: ProposalRepo) {}

  resolve(route: ActivatedRouteSnapshot): void {
    this.proposalRepo
      .selectActiveSiteHasProposal()
      .pipe(
        filter(Boolean),
        take(1),
        timeoutWith(this.hasProposalTimeout, of(false)),
      )
      .subscribe((has) => {
        if (has) {
          this.getProposedPVImages(
            this.extractProjectId(route),
            this.extractSiteId(route),
          );
        }
      });
  }

  private get hasProposalTimeout(): number {
    return 2000;
  }

  private getProposedPVImages(project: string, site: string): void {
    this.proposalRepo
      .getProposedPVImages(project, site)
      .pipe(take(1))
      .subscribe();
  }

  private extractSiteId(from: ActivatedRouteSnapshot): string {
    return Coerce.string(from.paramMap.get('siteId'));
  }

  private extractProjectId(from: ActivatedRouteSnapshot): string {
    return Coerce.string(from.paramMap.get('projectId'));
  }
}
