import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { SectionHeaderComponent } from './section-header.component';

@NgModule({
  declarations: [SectionHeaderComponent],
  imports: [CommonModule, MatIconModule, FlexLayoutModule],
  exports: [SectionHeaderComponent],
})
export class SectionHeaderModule {}
