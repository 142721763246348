import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SiteStatusModule } from '../site-status';
import { ContentHeaderComponent } from './content-header.component';

@NgModule({
  declarations: [ContentHeaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    SiteStatusModule,
  ],
  exports: [ContentHeaderComponent],
})
export class ContentHeaderModule {}
