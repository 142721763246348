import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';

import { SitesUploadFormModule } from '../sites-upload-form';
import { SiteImportDialogComponent } from './site-import-dialog.component';

@NgModule({
  declarations: [SiteImportDialogComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    SitesUploadFormModule,
    TranslocoModule,
  ],
  exports: [SiteImportDialogComponent],
})
export class SiteImportDialogModule {}
