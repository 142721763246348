import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { ReactiveComponentModule } from '@ngrx/component';

import { SiteMapToolboxModule } from '../site-map-toolbox';
import { SiteMapComponent } from './site-map.component';

@NgModule({
  declarations: [SiteMapComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    ReactiveComponentModule,
    SiteMapToolboxModule,
  ],
  exports: [SiteMapComponent],
})
export class SiteMapModule {}
