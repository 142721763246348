import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  ControlsOf,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SiteSolarPotential } from './site-solar-potential.model';

@UntilDestroy()
@Component({
  selector: 'mpv-site-solar-potential-form',
  templateUrl: './site-solar-potential-form.component.html',
  styleUrls: ['./site-solar-potential-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteSolarPotentialFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SiteSolarPotentialFormComponent),
      multi: true,
    },
  ],
})
export class SiteSolarPotentialFormComponent
  extends ControlValueAccessor<SiteSolarPotential>
  implements Validator, OnInit
{
  sitePotentialForm: FormGroup<ControlsOf<SiteSolarPotential>>;

  get installedCapacity() {
    return this.sitePotentialForm.get('installedCapacity');
  }

  get numOfPvModules() {
    return this.sitePotentialForm.get('numOfPvModules');
  }

  constructor() {
    super();

    this.sitePotentialForm = new FormGroup<ControlsOf<SiteSolarPotential>>({
      installedCapacity: new FormControl(null, Validators.min(0)),
      numOfPvModules: new FormControl(null, Validators.min(0)),
      yearlyProduction: new FormControl(null),
    });
  }
  ngOnInit(): void {
    this.sitePotentialForm.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChange!(value));
  }
  writeValue(value: SiteSolarPotential): void {
    this.sitePotentialForm.patchValue(value);
  }
  validate(_: AbstractControl): ValidationErrors | null {
    return this.sitePotentialForm.invalid ? { invalid: true } : null;
  }
  setDisabledState(isDisabled: boolean) {
    isDisabled
      ? this.sitePotentialForm.disable()
      : this.sitePotentialForm.enable();
  }
}
