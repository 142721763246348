import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';

import { SiteImportLogsComponent } from './site-import-logs.component';

@NgModule({
  declarations: [SiteImportLogsComponent],
  imports: [CommonModule, MatExpansionModule, FlexLayoutModule],
})
export class SiteImportLogsModule {}
