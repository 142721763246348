import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@multisite-pv/environment.token';
import { Coerce } from '@multisite-pv/shared/utils';
import { createStore, filterNil, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  GetProposedPVImagesRsp,
  initializeState,
  ProposalState,
} from './proposal.state';
import { SiteRepository } from './site.repository';

@Injectable({ providedIn: 'root' })
export class ProposalRepo {
  readonly store = createStore(
    { name: 'proposals' },
    withProps<ProposalState>(initializeState()),
  );

  constructor(
    private readonly siteRepo: SiteRepository,
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly baseUrl: string,
  ) {}

  getValue(): ProposalState {
    return this.store.getValue();
  }

  selectActiveSiteHasProposal(): Observable<boolean> {
    return this.siteRepo.selectedEntity$.pipe(
      filterNil(),
      map((site) => !!site.numOfPvModules),
    );
  }

  getProposedPVImages(project: string, site: string): Observable<unknown> {
    return this.http
      .get<GetProposedPVImagesRsp>(this.buildGetPVImagesUrl(project, site))
      .pipe(tap((rsp) => this.upsertProposal(site, rsp.urls)));
  }

  selectProposal(site: string): Observable<string[]> {
    return this.store.pipe(map((state) => Coerce.array(state.proposals[site])));
  }

  selectActiveSiteProposal(): Observable<string[]> {
    return this.siteRepo.selectedEntity$.pipe(
      mergeMap((site) => this.selectProposal(Coerce.object(site).id)),
    );
  }

  private upsertProposal(site: string, urls: string[]): void {
    this.store.update((state) => ({
      ...state,
      proposals: {
        ...state.proposals,
        [site]: urls,
      },
    }));
  }

  private buildGetPVImagesUrl(project: string, site: string): string {
    return `${this.baseUrl}/projects/${project}/sites/${site}/results/pvImages`;
  }
}
