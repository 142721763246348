<form
  *transloco="let t; read: 'site.savings'"
  [formGroup]="savingsForm"
  data-testid="savingsForm"
  fxLayout="row wrap"
  fxLayoutGap="1rem"
>
  <mat-form-field fxFlex>
    <mat-label>{{ t('autoConsumedEnergy.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="autoConsumedEnergy"
      data-testid="autoConsumedEnergy"
    />

    <mpv-field-suffix
      data-testid="autoConsumedEnergySuffix"
      matSuffix
      helpyKey="autoConsumedEnergy"
      [unit]="t('autoConsumedEnergy.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="autoConsumedEnergy?.errors?.min"
      data-testid="autoConsumedEnergy-positive"
      >{{ t('autoConsumedEnergy.messages.positive') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('autoConsumptionRatio.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="autoConsumptionRatio"
      data-testid="autoConsumptionRatio"
    />
    <mpv-field-suffix
      data-testid="autoConsumptionRatioSuffix"
      matSuffix
      helpyKey="autoConsumptionRatio"
      [unit]="t('autoConsumptionRatio.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="autoConsumptionRatio?.hasError"
      data-testid="autoConsumptionRatio-mustBeBetweenZeroToHundred"
      >{{ t('autoConsumptionRatio.messages.zeroToHundred') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('gridTariff.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="gridTariff"
      data-testid="gridTariff"
    />
    <mpv-field-suffix
      data-testid="gridTariffSuffix"
      matSuffix
      helpyKey="gridTariff"
      [unit]="t('gridTariff.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="gridTariff?.errors?.min"
      data-testid="gridTariff-positive"
      >{{ t('gridTariff.messages.positive') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('saving.label') }}</mat-label>
    <input
      type="number"
      matInput
      formControlName="savings"
      data-testid="savings"
    />
    <mpv-field-suffix
      data-testid="savingsSuffix"
      matSuffix
      helpyKey="savings"
      [unit]="t('saving.unit')"
    ></mpv-field-suffix>
    <mat-error *ngIf="savings?.errors?.min" data-testid="savings-positive">{{
      t('saving.messages.positive')
    }}</mat-error>
  </mat-form-field>
</form>

<div data-testid="consumptionProfiles"></div>
