import { InjectionToken } from '@angular/core';

import { APIConfig, MapConfig, WebSocketConfig } from './environment.model';

export const IS_ENV_PRODUCTION = new InjectionToken<boolean>(
  'is-env-production',
);

export const API_BASE_URL = new InjectionToken<string>('api-base-url');
export const API_CONFIG = new InjectionToken<APIConfig>('api-config');

export const WEB_SOCKET_URL = new InjectionToken<string>('web-socket-url');
export const WEB_SOCKET_CONFIG = new InjectionToken<WebSocketConfig>(
  'web-socket-config',
);

export const MAP_CONFIG = new InjectionToken<MapConfig>('map-config');
