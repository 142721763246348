<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="1rem"
  *transloco="let t; read: 'messageDialog'"
>
  <h2 data-testid="title">{{ data?.title }}</h2>
  <span data-testid="message">
    {{ t('messages.' + data?.mode, { name: data?.name }) }}
  </span>

  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
    <button
      mat-button
      color="primary"
      data-testid="cancel-button"
      (click)="onCancel()"
    >
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      data-testid="confirm-button"
      (click)="onConfirm()"
    >
      Confirm
    </button>
  </div>
</div>
