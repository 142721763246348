<ng-container *transloco="let t; read: 'plotly'">
  <div class="mpv-no-data" *ngIf="!data.length; else hasData">
    <span data-testid="no-data-message">{{ t('messages.noData') }}</span>
  </div>
  <ng-template #hasData>
    <plotly-plot
      data-testid="plotly"
      [data]="data"
      [layout]="layout"
    ></plotly-plot>
  </ng-template>
</ng-container>
