<ng-container *transloco="let t; read: 'site.map.dialog'">
  <h1 mat-dialog-title>{{ t('label') }}</h1>

  <mpv-site-map
    data-testid="site-map"
    height="480"
    [polygons]="polygons"
    [editable]="true"
    [center]="center"
    [projectId]="projectId"
    [siteId]="siteId"
    [bounds]="bounds"
    (updatedPolygon)="updatePolygons($event)"
    (polygonSelected)="onSelectPolygon($event)"
  ></mpv-site-map>

  <p
    class="mpv-site-map-hint mat-hint"
    data-testid="polygon-hints"
    [innerHTML]="
      (selected$ | ngrxPush) ? t('hint.selected') : t('hint.default')
    "
  ></p>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-button
      color="primary"
      data-testid="cancel-button"
      (click)="onCancel()"
    >
      {{ t('actions.cancel.label') }}
    </button>

    <button
      mat-flat-button
      color="primary"
      data-testid="save-button"
      (click)="onSave()"
    >
      {{ t('actions.save.label') }}
    </button>
  </div>
</ng-container>
