<mpv-page-content
  *transloco="let t; read: 'project'"
  [formGroup]="projectDetailsForm"
  data-testid="content"
>
  <div
    fxLayout="column"
    fxLayoutGap="1.5rem"
    *ngrxLet="selectedProject$ as project"
  >
    <div
      class="mpv-project-details-container"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="3rem"
    >
      <div class="mpv-project-pv-details">
        <mpv-section-header
          data-testid="pv-details-header"
          [icon]="t('pvDetails.icon')"
          [title]="t('pvDetails.label')"
        ></mpv-section-header>

        <mpv-project-pv-details-form
          *ngIf="!project.loading; else loading"
          formControlName="pvDetails"
          data-testid="pv-details-form"
          [readOnly]="true"
        ></mpv-project-pv-details-form>

        <ng-template #loading>
          <div
            fxLayout="row"
            fxLayoutGap="1rem"
            data-testid="pv-details-loading"
          >
            <ngx-skeleton-loader
              [theme]="loaderCss.input"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              [theme]="loaderCss.input"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              [theme]="loaderCss.input"
            ></ngx-skeleton-loader>
          </div>
        </ng-template>
      </div>

      <div class="mpv-project-pv-details">
        <mpv-section-header
          data-testid="sites-list-header"
          [icon]="t('sites.icon')"
          [title]="t('sites.label')"
        ></mpv-section-header>
        <mpv-site-list
          data-testid="sites-list-table"
          *ngIf="!project.loading; else siteLoading"
          [selectedProject]="project"
        ></mpv-site-list>

        <ng-template #siteLoading>
          <div fxLayoutGap="10%">
            <ngx-skeleton-loader [count]="2"></ngx-skeleton-loader>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</mpv-page-content>
<ng-template #loading> </ng-template>
