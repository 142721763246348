import { NgModule } from '@angular/core';
import { GenericErrorModule } from '@multisite-pv/shared/components/generic-error';
import { TranslocoModule } from '@ngneat/transloco';

import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [GenericErrorModule, TranslocoModule],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundModule {}
