<form
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  fxLayoutGap="1rem"
  *transloco="let t; read: 'project'"
  [formGroup]="pvDetailsForm"
  data-testid="projectPvForm"
>
  <mat-form-field>
    <mat-label>{{ t('labels.pvModuleHeight') }}</mat-label>
    <input
      matInput
      required
      type="number"
      [placeholder]="t('labels.pvModuleHeight')"
      formControlName="pvHeight"
      data-testid="pvModuleHeight"
      [readonly]="readOnly"
    />
    <mat-error
      data-testid="pvModuleHeight-required"
      *ngIf="pvHeight.errors?.required"
      >{{
        t('messages.errors.required', { field: 'PV Module Height' })
      }}</mat-error
    >
    <mat-error
      data-testid="pvModuleHeight-more-than-zero"
      *ngIf="pvHeight.errors?.min"
      >{{ t('messages.errors.min') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ t('labels.pvModuleWidth') }}</mat-label>
    <input
      matInput
      required
      type="number"
      [placeholder]="t('labels.pvModuleWidth')"
      formControlName="pvWidth"
      data-testid="pvModuleWidth"
      [readonly]="readOnly"
    />
    <mat-error
      data-testid="pvModuleWidth-required"
      *ngIf="pvWidth.errors?.required"
      >{{
        t('messages.errors.required', { field: 'PV Module Width' })
      }}</mat-error
    >
    <mat-error
      data-testid="pvModuleWidth-more-than-zero"
      *ngIf="pvWidth.errors?.min"
      >{{ t('messages.errors.min') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ t('labels.pvModuleCapacity') }}</mat-label>
    <input
      matInput
      required
      type="number"
      [placeholder]="t('labels.pvModuleCapacity')"
      formControlName="pvCapacity"
      data-testid="pvModuleCapacity"
      [readonly]="readOnly"
    />
    <mat-error
      data-testid="pvModuleCapacity-required"
      *ngIf="pvCapacity.errors?.required"
      >{{
        t('messages.errors.required', { field: 'PV Module Capacity' })
      }}</mat-error
    >
    <mat-error
      data-testid="pvModuleCapacity-more-than-zero"
      *ngIf="pvCapacity.errors?.min"
      >{{ t('messages.errors.min') }}</mat-error
    >
  </mat-form-field>
</form>
