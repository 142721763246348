import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'prod',
  production: true,
  auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_XMtmXZn73',
    userPoolWebClientId: '308p9rvdkvdks3siqatvcm8fp1',
    userPoolWebClientSecret: '',
    oauth: {
      domain: 'multisitepv-prod.auth.eu-west-1.amazoncognito.com',
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
        'profile',
      ],
      redirectSignIn: 'https://prod.multisitepv.te-ded.com/dashboard',
      redirectSignOut: 'https://prod.multisitepv.te-ded.com/home',
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: true,
      },
    },
  },
  api: {
    baseUrl: 'https://api.prod.multisitepv.te-ded.com',
  },
  sentry: {
    dsn: 'https://386050a86a7645e8a34a61e0b215570a@o258615.ingest.sentry.io/6546944',
  },
  webSocket: {
    url: 'wss://wss.prod.multisitepv.te-ded.com',
  },
  map: {
    api: 'https://maps.googleapis.com/maps/api/js',
    apiKey: 'AIzaSyDQzZnZOFjaGzHxA2zTDNs3644Xbk3bLWk',
  },
};
