import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { SiteStatusComponent } from './site-status.component';

@NgModule({
  declarations: [SiteStatusComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslocoModule,
    MatTooltipModule,
  ],
  exports: [SiteStatusComponent],
})
export class SiteStatusModule {}
