import { map } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStateRepository } from '@multisite-pv/shared/services';
import { upsertEntities } from '@ngneat/elf-entities';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Project } from '../project.model';
import { ProjectService } from '../project.service';
import { ProjectState, projectStore } from './project.store';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ProjectRepository extends EntityStateRepository<
  ProjectState,
  Project
> {
  constructor(private projectService: ProjectService) {
    super(projectStore);
  }

  projects$ = this.entities$.pipe(
    map((projects) => projects.filter((project) => !!project.id)),
  );

  createProject(project: Project) {
    this.addEntity({ ...project, loading: true });
    this.projectService.createProject(project).subscribe(
      (entity) => this.upsertEntity(project.id, { ...entity, loading: false }),
      ({ error: { error }, status }: HttpErrorResponse) =>
        this.upsertEntity(project.id, {
          error: `errorCodes.${error || status}`,
          loading: false,
        }),
    );
  }

  getProjects() {
    this.setState({ loading: true, error: null });
    this.projectService.getProjects().subscribe(
      (projects) =>
        projectStore.update(
          (state) => ({ ...state, loading: false, loaded: true }),
          upsertEntities(
            projects.map((project) => ({
              ...project,
              loading: false,
            })),
          ),
        ),
      ({ error: { error }, status }: HttpErrorResponse) =>
        this.setState({
          loading: false,
          loaded: false,
          error: `errorCodes.${error || status}`,
        }),
    );
  }

  getProject(projectId: string) {
    this.upsertEntity(projectId, { loading: true, error: null });
    this.projectService.getProject(projectId).subscribe(
      (entity) =>
        this.upsertEntity(projectId, {
          ...entity,
          loading: false,
          loaded: true,
          status: null,
        }),
      ({ error: { error }, status }: HttpErrorResponse) =>
        this.upsertEntity(projectId, {
          loading: false,
          error: `errorCodes.${error || status}`,
        }),
    );
  }

  setProjectState(projectId: string, states: any) {
    this.upsertEntity(projectId, { ...states });
  }
}
