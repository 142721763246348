import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';
import { HelpyComponent } from './helpy.component';

@NgModule({
  declarations: [HelpyComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslocoModule,
    MatTooltipModule,
    ReactiveComponentModule,
  ],
  exports: [HelpyComponent],
})
export class HelpyModule {}
