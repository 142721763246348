import { State } from '@multisite-pv/shared/model';
import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

import { Site } from '../site.model';

export const siteStore = createStore(
  { name: 'sites' },
  withProps<State>({ loaded: false, loading: false }),
  withEntities<Site>(),
  withActiveId(),
);
siteStore.getValue();
export type SiteState = typeof siteStore.state;
