<form
  *transloco="let t; read: 'site.solarPotential'"
  [formGroup]="sitePotentialForm"
  data-testid="sitePotentialForm"
  fxLayout="row wrap"
  fxLayoutGap="1rem"
>
  <mat-form-field fxFlex>
    <mat-label>{{ t('numOfPvModules.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="numOfPvModules"
      data-testid="numOfPvModules"
    />
    <mat-error
      *ngIf="numOfPvModules?.errors?.min"
      data-testid="numOfPvModules-positive"
      >{{ t('numOfPvModules.messages.positive') }}</mat-error
    >
    <mpv-field-suffix
      data-test="numOfPvModulesSuffix"
      matSuffix
      helpyKey="numOfPvModules"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('installedCapacity.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="installedCapacity"
      data-testid="installedCapacity"
    />
    <mpv-field-suffix
      data-test="installedCapacitySuffix"
      matSuffix
      helpyKey="installedCapacity"
      [unit]="t('installedCapacity.unit')"
    ></mpv-field-suffix>
    <mat-error
      *ngIf="installedCapacity?.errors?.min"
      data-testid="installedCapacity-positive"
      >{{ t('installedCapacity.messages.positive') }}</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('yearlyProduction.label') }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="yearlyProduction"
      data-testid="yearlyProduction"
      readonly
    />
    <mpv-field-suffix
      data-test="yearlyProductionSuffix"
      matSuffix
      helpyKey="yearlyProduction"
      [unit]="t('yearlyProduction.unit')"
    ></mpv-field-suffix>
  </mat-form-field>
</form>
