import { first, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectRepository } from '@multisite-pv/+project/store';
import { STATUS } from '@multisite-pv/app.reference';
import { StatusModel } from '@multisite-pv/shared/model';
import { EntityStateRepository } from '@multisite-pv/shared/services';
import { upsertEntities } from '@ngneat/elf-entities';
import { UntilDestroy } from '@ngneat/until-destroy';

import { MapPolygon } from '../site-map';
import { SignedUrl, Site } from '../site.model';
import { SiteService } from '../site.service';
import { SiteState, siteStore } from './site.store';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SiteRepository extends EntityStateRepository<SiteState, Site> {
  constructor(
    private siteService: SiteService,
    private projectRepository: ProjectRepository,
  ) {
    super(siteStore);
  }
  filterSites$ = this.entities$.pipe(
    withLatestFrom(this.projectRepository.selectedEntity$),
    map(([sites, project]) =>
      sites
        .filter((site) => site.projectId === project?.id)
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    ),
  );

  getSites(projectId: string) {
    this.setState({ loading: true, error: null });
    this.siteService.getSites(projectId).subscribe(
      (sites) => {
        siteStore.update(
          (state) => ({ ...state, loading: false, loaded: true }),
          upsertEntities(
            sites.map((project) => ({
              ...project,
              loading: false,
            })),
          ),
        );
      },
      ({ error: { error }, status }: HttpErrorResponse) =>
        this.setState({
          loading: false,
          loaded: false,
          error: `errorCodes.${error || status}`,
        }),
    );
  }

  updateSite(projectId: string, site: Site) {
    this.updateEntity(site.id, {
      loading: true,
      error: null,
    });
    this.siteService.updateSite(projectId, site).subscribe(
      (site: any) => {
        this.upsertEntity(site?.id, { ...site, loading: false, loaded: true });
      },
      ({ error: { error }, status }: HttpErrorResponse) =>
        this.upsertEntity(site.id, {
          loading: false,
          loaded: false,
          error: `errorCodes.${error || status}`,
        }),
    );
  }

  getSite(projectId: string, siteId: string) {
    this.upsertEntity(siteId, { loading: true, error: null });
    this.siteService.getSite(projectId, siteId).subscribe(
      (site: any) => {
        this.upsertEntity(site?.id, { ...site, loading: false, loaded: true });
      },
      ({ error: { error }, status }: HttpErrorResponse) =>
        this.upsertEntity(siteId, {
          loading: false,
          loaded: false,
          error: `errorCodes.${error || status}`,
        }),
    );
  }

  uploadSites(projectId: string, file: File) {
    this.projectRepository.setProjectState(projectId, {
      uploading: true,
      error: null,
      status: STATUS.ONGOING,
      errorMessages: null,
    });
    this.siteService
      .getImportSignedUrl(projectId)
      .pipe(
        mergeMap((signedUrl: any) =>
          this.siteService.uploadFile(signedUrl['url'], file),
        ),
      )
      .subscribe({
        error: ({ error: { error }, status }: HttpErrorResponse) =>
          this.projectRepository.setProjectState(projectId, {
            uploading: false,
            errorMessages: `errorCodes.${error || status}`,
            status: STATUS.FAILED,
          }),
      });
  }

  processUpdate(projectId: string, status: StatusModel) {
    this.projectRepository.setProjectState(projectId, {
      uploading: true,
      error: null,
      status: STATUS.ONGOING,
      errorMessages: null,
    });
    if (status.status === STATUS.SUCCESS) {
      this.resetEntities();
      this.getSites(projectId);
    }
    this.projectRepository.setProjectState(projectId, {
      uploading: false,
      error: null,
      ...status,
    });
  }

  getPolygons(projectId: string, siteId: string) {
    this.updateEntity(siteId, { polygonsLoading: true });
    this.siteService.getPolygons(projectId, siteId).subscribe(
      (polygons) =>
        this.updateEntity(siteId, { polygons, polygonsLoading: false }),
      (error) =>
        this.updateEntity(siteId, {
          polygonsError: error,
          polygonsLoading: false,
        }),
    );
  }

  savePolygons(projectId: string, siteId: string, polygons: Array<MapPolygon>) {
    this.updateEntity(siteId, {
      polygonsLoading: true,
      status: STATUS.ONGOING,
    });
    this.siteService.savePolygons(projectId, siteId, polygons).subscribe(
      () => this.getPolygons(projectId, siteId),
      (error) =>
        this.updateEntity(siteId, {
          polygonsError: error,
          polygonsSaving: false,
          polygonsLoading: false,
        }),
    );
  }

  getComputationUpdate(projectId: string, siteId: string, status: StatusModel) {
    if (status.status !== STATUS.ONGOING) {
      this.updateEntity(siteId, {
        ...status,
        polygonsLoading: false,
      });
      this.getSite(projectId, siteId);
    } else {
      this.updateEntity(siteId, {
        loading: false,
        error: null,
        ...status,
        polygonsLoading: true,
      });
    }
  }

  getProfiles(projectId: string, siteId: string, profileType: string) {
    this.updateEntity(siteId, { [`${profileType}Loading`]: true });
    this.siteService.getProfiles(projectId, siteId, profileType).subscribe(
      (url) => this.downloadProfiles(siteId, url, profileType),
      (error) =>
        this.updateEntity(siteId, {
          error: error,
          [`${profileType}Loading`]: false,
        }),
    );
  }

  downloadProfiles(siteId: string, url: string, profileType: string) {
    this.updateEntity(siteId, { [`${profileType}Loading`]: true });
    this.siteService.downloadProfiles(url, profileType).subscribe(
      (profiles) =>
        this.updateEntity(siteId, {
          [profileType]: profiles,
          [`${profileType}Loading`]: false,
        }),
      (error) =>
        this.updateEntity(siteId, {
          error: error,
          [`${profileType}Loading`]: false,
        }),
    );
  }

  exportSites(projectId: string) {
    this.siteService.exportSites(projectId).subscribe(
      () =>
        this.projectRepository.setProjectState(projectId, {
          exporting: true,
          exported: false,
        }),
      (error) =>
        this.projectRepository.setProjectState(projectId, {
          exporting: false,
          exported: false,
          exportError: `errorCodes.${error || status}`,
        }),
    );
  }

  downloadExport(projectId: string, signedUrl: SignedUrl) {
    if (signedUrl.url != '' || undefined) {
      this.siteService
        .downloadExported(signedUrl)
        .pipe(first())
        .subscribe(
          () =>
            this.projectRepository.setProjectState(projectId, {
              exporting: false,
              exported: true,
            }),
          (error) =>
            this.projectRepository.setProjectState(projectId, {
              exporting: false,
              exported: false,
              exportError: `errorCodes.${error}`,
            }),
        );
    }
  }
}
