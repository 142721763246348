<div class="impression-div">
  <div
    data-test="mapMode"
    *ngIf="selectedImpression.value === 'map'; else proposal"
  >
    <ng-content></ng-content>
  </div>
  <ng-template #proposal>
    <mpv-site-proposal data-test="proposalMode"></mpv-site-proposal>
  </ng-template>
</div>
<div
  fxLayout="row"
  fxLayoutAlign="end center"
  *ngrxLet="hasProposal$ as hasProposal"
>
  <span class="proposal-hint" *ngIf="hasProposal">
    Proposed site PV layout is available!
  </span>
  <mat-button-toggle-group
    data-test="impressionToggle"
    [formControl]="selectedImpression"
  >
    <mat-button-toggle
      value="proposal"
      data-test="proposal"
      [disabled]="!hasProposal"
    >
      <mat-icon [inline]="true" class="proposal-icon">solar_power</mat-icon>
      <div class="proposal-available-indicator" *ngIf="hasProposal"></div>
    </mat-button-toggle>
    <mat-button-toggle value="map" data-test="map">
      <mat-icon [inline]="true">travel_explore</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
