<div fxLayout="row" fxLayoutAlign="space-between center">
  <ng-content select="[header]"></ng-content>

  <span fxLayout="row" fxLayoutGap="0.25rem">
    <ng-content select="[actions]"></ng-content>
  </span>
</div>

<ng-content></ng-content>

<ng-content select="[footer]"></ng-content>
