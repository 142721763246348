<form
  *transloco="let t; read: 'site.economics'"
  [formGroup]="economicsForm"
  data-testid="economics"
  fxLayout="row wrap"
  fxLayoutGap="1rem"
>
  <mat-form-field fxFlex>
    <mat-label>{{ t('wacc.label') }}</mat-label>
    <input matInput formControlName="wacc" data-testid="wacc" type="number" />
    <mpv-field-suffix
      matSuffix
      data-test="waccSuffix"
      helpyKey="wacc"
      [unit]="t('wacc.unit')"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('projectDuration.label') }}</mat-label>
    <input
      matInput
      formControlName="projectDuration"
      data-testid="project-duration"
      type="number"
    />
    <mpv-field-suffix
      matSuffix
      data-test="projectDurationSuffix"
      helpyKey="projectDuration"
      [unit]="t('projectDuration.unit')"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('capex.label') }}</mat-label>
    <input matInput formControlName="capex" data-testid="capex" type="number" />
    <mpv-field-suffix
      matSuffix
      data-test="capexSuffix"
      helpyKey="capex"
      [unit]="t('capex.unit')"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('opex.label') }}</mat-label>
    <input matInput formControlName="opex" data-testid="opex" type="number" />
    <mpv-field-suffix
      matSuffix
      data-test="opexSuffix"
      helpyKey="opex"
      [unit]="t('opex.unit')"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('discountRate.label') }}</mat-label>
    <input
      matInput
      formControlName="discountRate"
      data-testid="discount-rate"
      type="number"
    />
    <mpv-field-suffix
      matSuffix
      data-test="discountRateSuffix"
      helpyKey="discountRate"
      [unit]="t('discountRate.unit')"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('degradationFactor.label') }}</mat-label>
    <input
      matInput
      formControlName="degradationFactor"
      data-testid="degradation-factor"
      type="number"
    />
    <mpv-field-suffix
      matSuffix
      data-test="degradationFactorSuffix"
      helpyKey="degradationFactor"
      [unit]="t('degradationFactor.unit')"
    ></mpv-field-suffix>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>{{ t('lcoe.label') }}</mat-label>
    <input
      matInput
      formControlName="lcoe"
      data-testid="lcoe"
      type="number"
      readonly
    />
    <mpv-field-suffix
      matSuffix
      data-test="lcoeSuffix"
      helpyKey="lcoe"
      [unit]="t('lcoe.unit')"
    ></mpv-field-suffix>
  </mat-form-field>
</form>
